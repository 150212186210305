import React, { useRef, createRef, useState, useEffect, useCallback } from "react";
import { json, useLocation, useParams } from "react-router-dom";
import { SidePanel, ProfileHeader } from "@components";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import Preloader from "../../plugins/Preloader";
import close_popup from "../../assets/icons/close_popup.svg";
import edit_pen from "../../assets/icons/edit-pen.svg";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from 'lodash';
import {
  GetAffiliateListByLoanId,
  GetAffiliateDetailsById,
  InsertUpdateAffiliateInfo,
  ActiveInactiveAffiliateById,
  censusNAICSVerify,
  DeleteAffiliateById,
} from "../../services/loanApplicationServices";
import { getSessionId } from "../../services/storageData";
import Constants from "../../helpers/Constants";
import reducersAndActions from "../../_redux/slices/index";
import { FormatPhoneNumber, decryptText } from "../../helpers/DynamicFunctions";
import { getMasterList } from "../../services/utilServices";
import {
  FieldGroup,
  FormBuilder,
  Validators,
  FieldControl,
} from "react-reactive-form";
import FormField from "../../components/FormField/FormField";
import { useNavigate } from "react-router-dom";
import DynamicTable from "../../plugins/DynamicTable";
///new code
import { LoanApplicationAffiliatesHeader } from '../../helpers/DropdownsAndMappers';
import '../styles/Users.css';

const contactNumberValidate = (control) => {
  // check if control.value only contains space or not
  let phoneValue =
    control.value &&
    control.value
      .split("(")
      .join("")
      .split(")")
      .join("")
      .split("-")
      .join("")
      .split(" ")
      .join("");
  if (phoneValue && phoneValue.trim() === "") {
    return { required: true };
  } else if (!phoneValue) {
    return { required: true };
  }
  if (!control.value) {
    return null;
  }
  if (phoneValue && phoneValue.length !== 10) {
    return { contactNumberIssue: true };
  }
  return null;
};

const businessNameValidator = (control) => {
  // check if control.value only contains space or not
  if (control.value && control.value.trim() === "") {
    return { extraSpaces: true };
  }
  if (!control.value) {
    return null;
  } else if (control.value && control.value.length > 100) {
    return { businessNameIssue: true };
  }
  return null;
};

const validateNumberOfEmployees = (control) => {
  // Check if the value is an empty string or a valid positive number
  if (!control.value || /^[0-9]+$/.test(control.value)) {
    return null; // valid
  }
  return { invalidNumber: true }; // invalid
}


// Reactive Form Group
const affiliateForm = FormBuilder.group({
  BusinessName: ["", [Validators.required, businessNameValidator]],
  DBA: [""],
  TypeOfBusiness: ["", [Validators.required]],
  BusinessCategory: [""],
  TaxID: ["", [Validators.required]],
  BusinessContactNo: ["", [Validators.required, contactNumberValidate, Validators.pattern("^[0-9 ()-]*$")]],
  NAICSCode: [0, [Validators.pattern('^[0-9]*$')]],
  NumberOfEmplyees: ["", [validateNumberOfEmployees]],
  BusinessEmail: ["", [Validators.required, Validators.email]],
});

let _loanId;
let TypeOfBusnessOptionsTemp = [];
let BusinessCategoriesOptionsTemp = [];

function Affiliates() {
  const params = useParams();
  const dispatch = useDispatch();
  const businessRef = createRef();
  const categoryRef = createRef();
  const location = useLocation()

  const userdetails = location.state?.data

  const user = useSelector((state) => state.auth);

  const [TypeOfBusnessOptions, setTypeOfBusnessOptions] = useState([]);
  const [BusinessCategoriesOptions, setBusinessCategoriesOptions] = useState([]);

  const [AffiliateId, setAffiliateId] = useState("");
  const [contactPhone, setContactPhone] = useState("")

  const [confirmRemove, setConfirmRemove] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(reducersAndActions.actions.sessionActions.setLoading(loading));
  }, [loading]);

  const [totalData, setTotalData] = useState(0);
  const [inquiries, setInquiries] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [contactTaxId, setContactTaxId] = useState("")
  const [naics, setNaics] = useState("")

  // Fetch inquiries whenever page size or page index changes
  const debouncedGetInquiries = useCallback(debounce(() => {
    if (_loanId) {
      getInquiries();
    }
  }, 300), [_loanId, pageIndex, pageSize, searchText]);

  useEffect(() => {
    debouncedGetInquiries();
  }, [debouncedGetInquiries]);

  // Function to fetch inquiries
  const getInquiries = async () => {
    try {
      setLoading(true);
      const storedSessionId = localStorage.getItem('sessionId');
      if (userdetails && _loanId) {
        _loanId = userdetails.data ? userdetails.data.loanId : userdetails.ApplicationNumber.slice(-4)
      }

      let queryObj = {
        PageIndex: pageIndex,
        PageSize: pageSize,
        LoanId: _loanId,
        SessionId: storedSessionId,
      };
      if (searchText) {
        queryObj["SearchText"] = searchText;
      }

      let response = await GetAffiliateListByLoanId(queryObj);

      if (response.status === 200 && response.resultObject) {
        let inquiries = JSON.parse(response.resultObject);
        setInquiries(inquiries);
        let total = response.message.split(" ");
        setTotalData(Number(total[1]));
      } else {
        setTotalData(0);
        setInquiries([]);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      setTotalData(0);
    }
  }

  // Preloader Start
  useEffect(() => {
    document.title = "Affiliates - Loan Application";
    let oid = decryptText(params.loanId);
    _loanId = oid;

    getMasterArrayList();
  }, []);
  // Preloader End

  const getMasterArrayList = async () => {
    try {
      let data1 = await getMasterList("TypeOfBusness");
      if (data1 && data1.status == 200) {
        let responseData1 = JSON.parse(data1.resultObject);
        const desiredOrder = [
          "Sole Proprietor",
          "Limited Liability Company (LLC)",
          "C-Corporation",
          "Partnership",
          "Independent Contractor",
          "S-Corporation"
        ];

        // Sort the response data according to the desired order
        responseData1.sort((a, b) => {
          return desiredOrder.indexOf(a.label) - desiredOrder.indexOf(b.label);
        });

        TypeOfBusnessOptionsTemp = responseData1;
        setTypeOfBusnessOptions(responseData1);
      }

      let data2 = await getMasterList("BusinessCategories");
      if (data2 && data2.status == 200) {
        let responseData2 = JSON.parse(data2.resultObject);
        const desiredOrder = [
          "Contractor",
          "Gas Station",
          "Hotel",
          "Medical",
          "Restaurant",
          "Transportation",
          "Others"
        ];
        responseData2.sort((a, b) => {
          return desiredOrder.indexOf(a.label) - desiredOrder.indexOf(b.label);
        });

        BusinessCategoriesOptionsTemp = responseData2;
        setBusinessCategoriesOptions(responseData2);
      }
    } catch (err) {
      console.log(err);
    }
  };


  const handleCheckboxChange = async (e, id) => {
    setLoading(true);
    const isChecked = !e.target.checked;

    let queryObj = {
      AffiliateId: parseInt(id),
      SessionId: getSessionId(),
    };

    let response = await ActiveInactiveAffiliateById(queryObj);
    if (response.status == 200) {
      dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.message, type: "success", }));
      setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIM);
      setLoading(false);
      getInquiries()
    } else {
      dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.message, type: "error", }));
      setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
      setLoading(false);
    }
  };

  const AffiliateAddEditModel = async (Id, _loanId) => {
    try {
      affiliateForm.reset();
      setAffiliateId(Id);
      let queryObj = {
        AffiliateId: Id,
        SessionId: getSessionId(),
      };

      if (Id > 0) {
        const modelAffiliateDetails = await GetAffiliateDetailsById(queryObj);
        if (modelAffiliateDetails.status == 200) {
          let finalData = modelAffiliateDetails.resultObject ? JSON.parse(modelAffiliateDetails.resultObject) : "";

          if (finalData && Array.isArray(finalData) && finalData.length > 0) {
            affiliateForm.patchValue({
              BusinessName: finalData[0].BusinessName,
              DBA: finalData[0].DBA,
              TypeOfBusiness: finalData[0].TypeOfBusiness,
              BusinessCategory: finalData[0].BusinessCategory,
              TaxID: finalData[0].TaxID,
              BusinessContactNo: finalData[0].BusinessContactNo,
              NAICSCode: finalData[0].NAICSCode,
              NumberOfEmplyees: finalData[0].NumberOfEmployee,
              BusinessEmail: finalData[0].BusinessEmail,
            });

            for (let elem = 0; elem < TypeOfBusnessOptionsTemp.length; elem++) {
              let elemvalue = TypeOfBusnessOptionsTemp[elem];
              if (elemvalue.value == finalData[0].TypeOfBusiness) {
                let key =
                  elemvalue.Identifier == "TypeOfBusness"
                    ? "TypeOfBusiness"
                    : null;
                affiliateForm.patchValue({
                  TypeOfBusiness: elemvalue,
                });
              }
            }

            for (
              let elem = 0;
              elem < BusinessCategoriesOptionsTemp.length;
              elem++
            ) {
              let elemvalue = BusinessCategoriesOptionsTemp[elem];
              if (elemvalue.value == finalData[0].BusinessCategory) {
                let key =
                  elemvalue.Identifier == "BusinessCategories"
                    ? "BusinessCategory"
                    : null;
                affiliateForm.patchValue({
                  BusinessCategory: elemvalue,
                });
              }
            }
          }
        }
      }

      setAffiliatesDrawer(true);
      setIsEditDrawer(Id > 0 ? true : false);
    } catch (err) {
      console.log(err);
      dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: err.message, type: "error", }));
      setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
    }
  };

  // Popup function
  const [isEditDrawer, setIsEditDrawer] = useState(false);
  const [affiliatesDrawerShow, setAffiliatesDrawer] = useState(false);
  const affiliatesDrawerClose = () => setAffiliatesDrawer(false);


  // ... existing code ...

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (affiliateForm.status !== "INVALID") {
      setLoading(true);
      // Check and set default values if any field is null
      Object.keys(affiliateForm.controls).forEach(key => {
        const control = affiliateForm.get(key);
        if (control?.value === null || control?.value === undefined) {
          control?.setValue("");  // Default to an empty string for text fields
        }
      });
      let finalObj = {
        ...affiliateForm.value,
        IsFlagSetForBusinessCategoryChange: false,
        UserId: 1,
        Id: AffiliateId,
        LoanId: _loanId,
        SessionId: getSessionId(),
      };

      finalObj = {
        ...finalObj,
        TypeOfBusiness: affiliateForm.value.TypeOfBusiness.value,
        BusinessCategory: affiliateForm.value.BusinessCategory.value,
      };


      try {
        const data = await InsertUpdateAffiliateInfo(finalObj);
        setLoading(false);
        if (data.status === 200) {
          dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: data.message, type: "success", }));
          setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);

          getInquiries();
          setAffiliatesDrawer(false);
        } else {
          dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: data.message, type: "error", }));
          setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        }
      } catch (err) {
        setLoading(false);
        console.log(err);
        dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: err.message, type: "error", }));
        setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
      }
    } else {
      markAllAsTouched(affiliateForm);
    }
  };


  // Marking all input fields as touched if the form is not valid
  const markAllAsTouched = (formGroup) => {
    if (businessRef.current) businessRef.current.focus();
    if (categoryRef.current) categoryRef.current.focus();

    Object.keys(formGroup.controls).forEach((controlName) => {
      const control = formGroup.get(controlName);
      if (control) {
        control.active = true;
        control.touched = true;
        if (document.getElementById(controlName)) {
          document.getElementById(controlName).focus();
        }
      }
    });
  };

  const dynamicTableDisplay = (data) => {

    return (
      <tr >
        {/* <td>
                {data.RowNumber}
            </td> */}
        <td>{data.BusinessName}</td>
        <td>{data.TaxID}</td>
        <td>
          {data.NAICSCode}
        </td>
        <td >{data.BusinessContactNo}
        </td>
        <td >{data.BusinessEmail}
        </td>
        <td > <div className="lm-toggle-switch">
          <input
            type="checkbox"
            id={`switch` + data.Id}
            checked={data.Isactive == "true" ? true : false}
            onChange={(e) => handleCheckboxChange(e, data.Id)}
          />
          <label htmlFor={`switch` + data.Id}>Status</label>
        </div>
        </td>
        <td>
          <a onClick={() => { AffiliateAddEditModel(data.Id, data.LoanId) }}        >
            <img className="lm-grid-edit-icon" src={edit_pen} alt="" />
          </a>
        </td>
      </tr>
    )
  };

  const selectRef = useRef(null);

  useEffect(() => {
    const handleOpen = (event) => {
      const selectElement = selectRef.current;
      if (selectElement) {
        const menuElement = selectElement.querySelector('.select__menu');
        if (menuElement) {
          const rect = selectElement.getBoundingClientRect();
          const spaceBelow = window.innerHeight - rect.bottom;
          const spaceAbove = rect.top;
          const menuHeight = menuElement.offsetHeight;

          if (spaceBelow < menuHeight && spaceAbove > spaceBelow) {
            menuElement.style.bottom = `${rect.height}px`;
            menuElement.style.top = 'auto';
          } else {
            menuElement.style.top = `${rect.height}px`;
            menuElement.style.bottom = 'auto';
          }
        }
      }
    };

    document.addEventListener('mousedown', handleOpen);
    return () => {
      document.removeEventListener('mousedown', handleOpen);
    };
  }, []);

  const handleRemoveAffiliate = async () => {
    try {
      setConfirmRemove(false);
      setLoading(true);
      
      const finalObj = {
        AffiliateId: AffiliateId,
        ModifiedBy: user.user.id,
        SessionId: getSessionId(),
      };
      const data = await DeleteAffiliateById(finalObj);
      setLoading(false);
      if (data.status === 200) {
        dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: data.message, type: "success", }));
        setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        getInquiries();
        setAffiliatesDrawer(false);
      } else {
        dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: data.message, type: "error", }));
        setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: err.message, type: "error", }));
      setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
    }
  }


  return (
    <>
      {/* SidePanel */}
      <SidePanel />

      {/* ProfileHeader */}
      <ProfileHeader />

      {loading ? (
        <Preloader />
      ) : (

        <div>
          {/* <!-- affiliates grid table start --> */}
          <div className="grid-head d-flex justify-content-between">
            <h3>Affiliates</h3>
            <a onClick={() => AffiliateAddEditModel(0, _loanId)} className="lm-button3">
              <span>+</span> Add a new affiliate
            </a>
          </div>

          <div className="lm-grid-table" id="myTable">

            <DynamicTable
              data={inquiries}
              dynamicTableDisplay={dynamicTableDisplay}
              columns={LoanApplicationAffiliatesHeader}
              changePageSize={setPageSize}
              pageSize={pageSize}
              total={totalData}
              setList={setInquiries}
              setPageIndex={setPageIndex}
              pageIndex={pageIndex}
            />

          </div>

          {/* <!-- affiliates grid table end --> */}

          {/* <!--affiliates form drawer start--> */}
          <Modal
            className="fade-right drawer-modal"
            show={affiliatesDrawerShow}
            onHide={() => setAffiliatesDrawer(false)}
            aria-labelledby="affiliatesDrawerLabel"
          >
            <div className="modal-dialog">
              <FieldGroup
                control={affiliateForm}
                render={({ get, invalid }) => (
                  <form>
                    <div className="modal-content">
                      <div className="drawer-head d-flex justify-content-between">
                        <button type="button" className="close" onClick={affiliatesDrawerClose}>
                          <span aria-hidden="true">
                            <img src={close_popup} alt="" />
                          </span>
                        </button>
                      </div>
                      <div className="drawer-body">
                        <div className="form-group">
                          <FormField
                            name="BusinessName"
                            label="Business Name"
                            placeholder="Business Name"
                            id="BusinessName"
                            required={true}
                            errorMessage={"Business Name is required"}
                            maxLength={150}
                          />
                        </div>
                        <div className="form-group">
                          <FieldControl
                            name="TaxID"
                            render={({ handler, touched, hasError, meta, }) => {
                              const handlerProps = handler();
                              const standardizeTaxID = (e) => {
                                let value = e.target.value.replace(/[^0-9]/g, ''); // Remove all non-numeric characters

                                // Format value as XX-XXXXXXX
                                if (value.length > 2) {
                                  value = value.slice(0, 2) + '-' + value.slice(2, 9); // Limit to 9 digits
                                }

                                // Update the input value
                                e.target.value = value;

                                // Trigger onChange handler if provided
                                if (handlerProps.onChange) {
                                  handlerProps.onChange(e);
                                }

                                // Validation
                                const taxIDPattern = /^\d{2}-\d{7}$/;
                                if (value.length === 0) {
                                  setContactTaxId(""); // Clear the error message
                                } else if (!taxIDPattern.test(value)) {
                                  setContactTaxId("Tax ID should be of 9 digits.");
                                } else {
                                  setContactTaxId(""); // Clear the error message
                                }
                              };


                              return (
                                <div className="form-group">
                                  <label htmlFor="TaxID">
                                    Business Tax ID
                                    <span className="asterisk">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="TaxID"
                                    label="Business Tax ID"
                                    placeholder="22-4351965"
                                    id="TaxID"
                                    {...handlerProps}
                                    onChange={standardizeTaxID}
                                  />

                                  {/* Error messages below */}
                                  <span className="error-message">{touched && hasError("required") && `Business Tax ID is required`}                                  </span>
                                  {contactTaxId && (<>  <span className="error-message">    {contactTaxId}  </span>  <br /></>)}
                                </div>
                              );
                            }}
                            meta={{ placeholder: "47-113478" }}
                          />
                        </div>
                        <div className="form-group">
                          <FieldControl
                            name="TypeOfBusiness"
                            render={({ handler, touched, hasError, meta, }) => (
                              <div className="form-group">
                                <label htmlFor="TypeOfBusiness">
                                  Type of business
                                  <span className="asterisk">*</span>
                                </label>
                                <Select
                                  className="basic-single"
                                  classNamePrefix="select"
                                  id="TypeOfBusiness"
                                  name="TypeOfBusiness"
                                  ref={businessRef}
                                  options={TypeOfBusnessOptions}
                                  {...handler()}
                                />
                                {/* Error messages below */}
                                <span className="error-message">{touched && hasError("required") && `Business type is required`}</span>
                              </div>
                            )}
                          />
                        </div>
                        <div className="form-group">
                          <FieldControl
                            name="BusinessContactNo"
                            render={({ handler, touched, hasError, meta, }) => {
                              const handlerProps = handler();
                              const standardizePhoneNumber = (e) => {
                                let value = e.target.value.replace(/[^0-9]/g, ''); // Remove all non-numeric characters

                                // Check if the user is trying to delete (backspace)
                                if (e.nativeEvent.inputType === 'deleteContentBackward') {
                                  if (handlerProps.onChange) {
                                    handlerProps.onChange(e);  // Update the input without reformatting
                                  }
                                  return;
                                }

                                // Format value as (XXX) XXX-XXXX when not deleting
                                if (value.length > 2) {
                                  value = "(" + value.slice(0, 3) + ')' + value.slice(3, 6) + "-" + value.slice(6, 10);
                                }

                                // Update the input value
                                e.target.value = value;
                                if (handlerProps.onChange) {
                                  handlerProps.onChange(e);
                                }

                                // Validate the phone number pattern
                                const phonePattern = /^\(\d{3}\) \d{3}-\d{4}$/;
                                const input = e.target.value;
                                const numericInput = input.replace(/\D/g, '');

                                if (input.length === 0) {
                                  setContactPhone("");
                                } else if (/[^0-9()-.\s]/.test(input)) {
                                  setContactPhone("Text is not allowed in the Business Phone Number.");
                                  return;
                                } else if (numericInput.length !== 10) {
                                  setContactPhone("Business Phone Number should be of 10 digits.");
                                } else {
                                  setContactPhone("");
                                }
                              };


                              return (
                                <div className="form-group">
                                  <label htmlFor="BusinessContactNo">
                                    Business Contact Number
                                    <span className="asterisk">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="BusinessContactNo"
                                    placeholder="(877) 847-1134"
                                    {...handlerProps}
                                    onChange={standardizePhoneNumber}
                                    maxLength={15}
                                  />

                                  {/* Error messages below */}
                                  {touched && hasError("required") && <><span className="error-message">Mobile Number is required</span><br /></>}
                                  {touched && hasError("contactNumberIssue") && <><span className="error-message">Mobile number should be of 10 digits.</span><br /></>}
                                  {touched && hasError("pattern") && <><span className="error-message">Mobile number should be of numeric value.</span><br /></>}
                                </div>
                              );
                            }}
                            meta={{ placeholder: "(877) 847-1134" }}
                          />

                        </div>
                        <div className="form-group">
                          <FieldControl
                            name="NAICSCode"
                            render={({ handler, touched, hasError, meta, }) => {
                              const handlerProps = handler();
                              const standardizeTaxID = (e) => {
                                let value = e.target.value.replace(/[^0-9]/g, ''); // Remove all non-numeric characters

                                // Truncate value to a maximum of 14 characters
                                if (value.length > 10) {
                                  value = value.slice(0, 10);
                                }

                                e.target.value = value;

                                if (handlerProps.onChange) {
                                  handlerProps.onChange(e);
                                }

                                if (value.length === 0) {
                                  setNaics("");
                                }
                              };

                              return (
                                <div className="form-group">
                                  <label htmlFor="NAICSCode">
                                    NAICS Code
                                  </label>
                                  <input
                                    type="text"
                                    name="NAICSCode"
                                    label="NAICS Code"
                                    placeholder="44110"
                                    id="NAICSCode"
                                    {...handlerProps}
                                    onChange={standardizeTaxID}
                                  />

                                  {/* Error messages below */}
                                  <span className="error-message">{touched && hasError("required") && `NAICSCode is required`}</span>
                                  {contactTaxId && (<>  <span className="error-message">    {naics}  </span>  <br /></>)}
                                </div>
                              );
                            }}
                            meta={{ placeholder: "47-113478" }}
                          />
                        </div>
                        <div className="form-group">
                          <FormField
                            name="DBA"
                            label="D/B/A"
                            placeholder="Enter d/b/a"
                            id="DBA"
                            required={false}
                            errorMessage={" is required"}
                            maxlength={150}
                          />
                        </div>
                        <div className="form-group">
                          <FieldControl
                            name="BusinessCategory"
                            render={({ handler, touched, hasError, meta, }) => (
                              <div className="form-group" ref={selectRef}>
                                <label htmlFor="BusinessCategory">
                                  Business Category
                                </label>
                                <Select
                                  className="basic-single"
                                  classNamePrefix="select"
                                  id="BusinessCategory"
                                  name="BusinessCategory"
                                  options={BusinessCategoriesOptions}
                                  {...handler()}
                                  styles={{
                                    menu: (provided) => ({
                                      ...provided,
                                      position: 'fixed',
                                      width: 'auto',
                                      minWidth: '100%',
                                    }),
                                  }}
                                />
                                {/* Error messages below */}
                                <span className="error-message">{touched && hasError("required") && `Business Category is required`}</span>
                              </div>
                            )}
                          />
                        </div>

                        <div className="form-group">
                          <FormField
                            name="NumberOfEmplyees"
                            label="Number of Employees"
                            placeholder="10"
                            id="NumberOfEmplyees"
                            required={false}
                            errorMessage={" is required"}
                            patternMessage="Number of employees should be numeric"
                            patternRequired={true}
                            maxlength={15}
                          />

                        </div>
                        <div className="form-group">
                          <FormField
                            name="BusinessEmail"
                            label="Business Email"
                            placeholder="Email Address"
                            id="BusinessEmail"
                            required={true}
                            errorMessage={"Business Email is required"}
                            maxlength={100}
                          />
                        </div>
                      </div>
                      <div className="drawer-footer gap-10 d-flex">
                        {
                          isEditDrawer && (
                            <button type="button" className="lm-button2 text-danger w-100" onClick={() => setConfirmRemove(true)}>Remove</button>
                          )
                        }
                        <button type="button" className={isEditDrawer ? "lm-button1 w-100" : "lm-button1 ml-auto"} onClick={handleSubmit}>Save</button>
                      </div>
                    </div>
                  </form>
                )}
              />
            </div>
          </Modal>
          {/* <!--affiliates form drawer end--> */}

          {/* <!-- Remove Affiliate Popup / Modal --> */}
          <Modal
            className='mark-comp-popup-cust-size'
            show={confirmRemove}
            onHide={() => setConfirmRemove(false)}
            aria-labelledby="markCompletePopupLabel"
            backdrop="static"
            keyboard={false}
            centered
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-body signout-modal">
                  <h3 className="pt-4">
                    Are you sure you want remove this affiliate?
                  </h3>
                </div>
                <div className="modal-footer la-app-modal-footer">
                  <button type="button" className="lm-button2" onClick={() => setConfirmRemove(false)}>Cancel</button>
                  <button type="button" className="lm-button1" onClick={handleRemoveAffiliate}>Confirm</button>
                </div>
              </div>
            </div>
          </Modal>
          {/* <!-- Remove Affiliate Popup / Modal --> */}
        </div>
      )}
    </>
  );
}

export default Affiliates;
