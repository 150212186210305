import { createSlice } from '@reduxjs/toolkit';

import { getLocalStorageData } from '../../services/storageData';
const user = JSON.parse(getLocalStorageData("user"));
const editProfile = createSlice({
  name: 'editProfile',
  initialState: {
    data: "",
    profile: "",
    profileDetailsrefresh: false,
    stateUpdate: "",
  },
  reducers: {
    uplaodData: (state, user) => {
      state.data = user.payload
    },
    profileUpdate: (state, user) => {
      state.profile = user.payload
    },
    refreshData: (state, user) => {
      state.data = user.payload
    },
    stateUpdateReducer: (state, user) => {
      state.stateUpdate = user.payload
    },
    isProfileUpdated: (state, user) => {
      state.profileDetailsrefresh = !state.profileDetailsrefresh
    }
  }
});

export const profileAction = editProfile.actions;
export default editProfile.reducer;