import React from 'react';
import CreatableSelect from 'react-select/creatable';

const CreatableSelectComponent = ({ name, label, value, onChange, placeholder, options, isMulti = true, isClearable = true, required = false }) => {
  const handleChange = (selectedOption) => {
    onChange(name, selectedOption);
  };

  return (
    <div>
      {label && <label>{label}{required && <span className="asterisk">*</span>}</label>}
      <CreatableSelect
        className="basic-single emailbox-input"
        classNamePrefix="select"
        name={name}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        options={options || []}
        isMulti={isMulti}
        isClearable={isClearable}
      />
    </div>
  );
};

export default CreatableSelectComponent;

