import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import Modal from "react-bootstrap/Modal";
import { SidePanel, ProfileHeader } from "@components";
import Preloader from "../../plugins/Preloader";
import {
  FieldGroup,
  FormBuilder,
  Validators,
  FieldControl,
} from "react-reactive-form";
import {
  GetAmortizationDetail,
  InsertUpdateAmortization,
} from "../../services/loanApplicationServices";
import { getSessionId } from "../../services/storageData";
import { decryptText, formatDate } from "../../helpers/DynamicFunctions";
import Constants from "../../helpers/Constants";
import reducersAndActions from "../../_redux/slices/index";

const formatNumberWithCommasAmount = (number) => {
  if (number.includes(".")) {
    let [integerPart, decimalPart] = number.toString().split(".");
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (decimalPart) {
      // limit the decimalPart digits upto 2 is its more than 2
      if (decimalPart.length > 2) {
        decimalPart = decimalPart.substring(0, 2);
      }
      return integerPart + "." + decimalPart;
    } else {
      return integerPart + ".";
    }
  } else {
    let integerPart = number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return integerPart;
  }
};

// Reactive Form Group
const AmortizationForm = FormBuilder.group({
  PurchaseRealProperty: ["", [Validators.pattern("^[0-9,.]*$")]],
  PurchaseImprovements: ["", [Validators.pattern("^[0-9,.]*$")]],
  PurchaseEquipment: ["", [Validators.pattern("^[0-9,.]*$")]],
  PurchaseFurnitureAndFixtures: ["", [Validators.pattern("^[0-9,.]*$")]],
  PurchaseInventory: ["", [Validators.pattern("^[0-9,.]*$")]],
  DebtRefinance: ["", [Validators.pattern("^[0-9,.]*$")]],
  BusinessAcquisition: ["", [Validators.pattern("^[0-9,.]*$")]],
  WorkingCapital: ["", [Validators.pattern("^[0-9,.]*$")]],
  ClosingCosts: ["", [Validators.pattern("^[0-9,.]*$")]],
  LessBorrowerDownPayment: ["", [Validators.pattern("^[0-9,.]*$")]],
  TotalLoanRequest: ["", [Validators.pattern("^[0-9,.]*$")]],
  SourceOfDownPayment: [""],
  SubTotal: ["", [Validators.pattern("^[0-9,.]*$")]],
  ClosingCostsPercentage: ["", [Validators.pattern("^[0-9,.]*$")]],
  SBAGuaranteeFees: ["", [Validators.pattern("^[0-9,.]*$")]],
  SBAGuaranteeFeesPercentage: ["", [Validators.pattern("^[0-9,.]*$")]],
  PersonalFunds: ["", [Validators.pattern("^[0-9,.]*$")]],
  OthersInvestment: ["", [Validators.pattern("^[0-9,.]*$")]],
  DownPaymentOthers: [""],
  EquityPercentage: ["", [Validators.pattern("^[0-9,.]*$")]],
  TotalAmount: ["", [Validators.pattern("^[0-9,.]*$")]],
  EquityDeal: ["", [Validators.pattern("^[0-9,.]*$")]],
  EquityClosing: ["", [Validators.pattern("^[0-9,.]*$")]],
  EquityLoanAmount: ["", [Validators.pattern("^[0-9,.]*$")]],
  TotalEquityAmount: ["", [Validators.pattern("^[0-9,.]*$")]],
  EmiStartDate: ["", Validators.required],
  InterestRate: ["", [Validators.required, Validators.pattern("^[0-9,.]*$")]],
  TermYear: ["", [Validators.required, Validators.pattern("^[0-9,.]*$")]],
});

const frmAmortization = FormBuilder.group({
  PurchaseRealProperty: [0],
  PurchaseImprovements: [0],
  PurchaseEquipment: [0],
  PurchaseFurnitureAndFixtures: [0],
  PurchaseInventory: [0],
  DebtRefinance: [0],
  BusinessAcquisition: [0],
  WorkingCapital: [0],
  ClosingCosts: [0],
  LessBorrowerDownPayment: [0],
  TotalLoanRequest: [0],
  SourceOfDownPayment: [""],
  SubTotal: [0],
  ClosingCostsPercentage: [0],
  SBAGuaranteeFees: [0],
  SBAGuaranteeFeesPercentage: [0],
  PersonalFunds: [0],
  OthersInvestment: [0],
  DownPaymentOthers: [0],
  EquityPercentage: [0],
  TotalAmount: [0],
  EquityDeal: [0],
  EquityClosing: [0],
  EquityLoanAmount: [0],
  TotalEquityAmount: [0],
});



let loanId;

function Amortization({ backPreviousTab }) {
  const dispatch = useDispatch();
  const params = useParams();

  // Meta Title
  useEffect(() => {
    document.title = "Amortization - Loan Application";
  }, []);

  // Preloader Start
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(reducersAndActions.actions.sessionActions.setLoading(loading));
  }, [loading]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(timeout);
  }, []);



  // Preloader End

  // Popup Function
  const [PreviousTabPopupShow, setPreviousTabPopup] = useState(false);
  const PreviousTabPopupClose = () => setPreviousTabPopup(false);

  // datepicker function
  const [startDate, setStartDate] = useState(new Date());

  const handleCalculateLoanAmount = (amount, name, e) => {
    amount = amount.replace(/,/g, "");

    if (amount == "" || Number(amount) < 0) {
      amount = 0;
    }
    if (name == "PurchaseRealProperty") {
      frmAmortization.patchValue({ PurchaseRealProperty: amount });
    } else if (name == "PurchaseImprovements") {
      frmAmortization.patchValue({ PurchaseImprovements: amount });
    } else if (name == "PurchaseEquipment") {
      frmAmortization.patchValue({ PurchaseEquipment: amount });
    } else if (name == "PurchaseFurniture") {
      frmAmortization.patchValue({ PurchaseFurnitureAndFixtures: amount });
    } else if (name == "PurchaseInventory") {
      frmAmortization.patchValue({ PurchaseInventory: amount });
    } else if (name == "DebtRefinance") {
      frmAmortization.patchValue({ DebtRefinance: amount });
    } else if (name == "BusinessAcquisition") {
      frmAmortization.patchValue({ BusinessAcquisition: amount });
    } else if (name == "WorkingCapital") {
      frmAmortization.patchValue({ WorkingCapital: amount });
    } else if (name == "OthersInvestment") {
      frmAmortization.patchValue({ OthersInvestment: amount });
    } else if (name == "ClosingCostsPercentage") {
      if (amount > 100) {
        amount = 0;
        e.target.value = 0;
        dispatch(
          reducersAndActions.actions.toasterActions.showToaster({
            visible: true,
            message: "Closing Costs Percentage should not be greater than 100.",
            type: "error",
          })
        );
        setTimeout(
          () =>
            dispatch(reducersAndActions.actions.toasterActions.hideToaster()),
          Constants.TOASTERHIDETIME
        );
      }

      frmAmortization.patchValue({ ClosingCostsPercentage: amount });
    } else if (name == "SBAGuaranteeFeesPercentage") {
      if (amount > 100) {
        amount = 0;
        e.target.value = 0;
        dispatch(
          reducersAndActions.actions.toasterActions.showToaster({
            visible: true,
            message:
              "SBA Guarantee Fees Percentage should not be greater than 100.",
            type: "error",
          })
        );
        setTimeout(
          () =>
            dispatch(reducersAndActions.actions.toasterActions.hideToaster()),
          Constants.TOASTERHIDETIME
        );
      }

      frmAmortization.patchValue({ SBAGuaranteeFeesPercentage: amount });
    } else if (name == "EquityPercentage") {
      if (amount > 100) {
        amount = 0;
        e.target.value = 0;
        dispatch(
          reducersAndActions.actions.toasterActions.showToaster({
            visible: true,
            message: "Equity Percentage should not be greater than 100.",
            type: "error",
          })
        );
        setTimeout(
          () =>
            dispatch(reducersAndActions.actions.toasterActions.hideToaster()),
          Constants.TOASTERHIDETIME
        );
      }
      frmAmortization.patchValue({ EquityPercentage: amount });
    } else if (name == "LessBorrowerDownPayment") {
      frmAmortization.patchValue({ LessBorrowerDownPayment: amount });
    }

    let sumTotal =
      parseFloat(frmAmortization.value.PurchaseRealProperty) +
      parseFloat(frmAmortization.value.PurchaseImprovements) +
      parseFloat(frmAmortization.value.PurchaseEquipment) +
      parseFloat(frmAmortization.value.PurchaseFurnitureAndFixtures) +
      parseFloat(frmAmortization.value.PurchaseInventory) +
      parseFloat(frmAmortization.value.DebtRefinance) +
      parseFloat(frmAmortization.value.BusinessAcquisition) +
      parseFloat(frmAmortization.value.WorkingCapital) +
      parseFloat(frmAmortization.value.OthersInvestment);

    let ClosingCost = 0;
    ClosingCost =
      (frmAmortization.value.ClosingCostsPercentage * sumTotal) / 100;

    AmortizationForm.patchValue({
      ClosingCosts: ClosingCost.toFixed(2),
    });

    let SBAGuaranteeFee = 0;
    SBAGuaranteeFee =
      (frmAmortization.value.SBAGuaranteeFeesPercentage *
        (sumTotal + ClosingCost)) /
      100;

    AmortizationForm.patchValue({
      SBAGuaranteeFees: SBAGuaranteeFee.toFixed(2),
    });

    let totalcost = 0;
    totalcost = sumTotal + ClosingCost + SBAGuaranteeFee;

    let Equitycost = 0;
    Equitycost = (frmAmortization.value.EquityPercentage * totalcost) / 100;

    AmortizationForm.patchValue({
      EquityDeal: Equitycost.toFixed(2),
    });

    if (frmAmortization.value.LessBorrowerDownPayment > Equitycost) {
      AmortizationForm.patchValue({
        LessBorrowerDownPayment: 0,
      });
      dispatch(
        reducersAndActions.actions.toasterActions.showToaster({
          visible: true,
          message:
            "Down payment should not be greater than Equity reqd. for Deal.",
          type: "error",
        })
      );
      setTimeout(
        () => dispatch(reducersAndActions.actions.toasterActions.hideToaster()),
        Constants.TOASTERHIDETIME
      );
    }

    let EquityLoanAmount = 0;
    EquityLoanAmount =
      parseFloat(Equitycost) -
      parseFloat(frmAmortization.value.LessBorrowerDownPayment);

    AmortizationForm.patchValue({
      SubTotal: (sumTotal).toFixed(2),
      TotalAmount: (totalcost).toFixed(2),
      EquityClosing: EquityLoanAmount.toFixed(2),
      EquityLoanAmount: (totalcost - Equitycost).toFixed(2),
      TotalEquityAmount: (totalcost).toFixed(2),
    });
  };

  useEffect(() => {
    let oid = decryptText(params.loanId);
    loanId = oid;
    getAmortizationInfo();
  }, []);

  const getAmortizationInfo = async () => {
    try {
      setLoading(true);
      AmortizationForm.reset();
      if (loanId != "" && parseInt(loanId) > 0) {
        let queryObj = {
          Id: loanId,
          SessionId: getSessionId(),
        };

        //console.log("Print Query OBJ", queryObj);

        const modelAmortizationDetail = await GetAmortizationDetail(queryObj);
        if (modelAmortizationDetail.status == 200) {
          let finalData = modelAmortizationDetail.resultObject
            ? JSON.parse(modelAmortizationDetail.resultObject)
            : "";

          if (finalData && Array.isArray(finalData) && finalData.length > 0) {
            AmortizationForm.patchValue({
              PurchaseRealProperty: finalData[0].PurchaseRealProperty,
              PurchaseImprovements: finalData[0].PurchaseImprovements,
              PurchaseEquipment: finalData[0].PurchaseEquipment,
              PurchaseFurnitureAndFixtures:
                finalData[0].PurchaseFurnitureAndFixtures,
              PurchaseInventory: finalData[0].PurchaseInventory,
              DebtRefinance: finalData[0].DebtRefinance,
              BusinessAcquisition: finalData[0].BusinessAcquisition,
              WorkingCapital: finalData[0].WorkingCapital,
              ClosingCosts: finalData[0].ClosingCosts,
              LessBorrowerDownPayment: finalData[0].LessBorrowerDownPayment,
              TotalLoanRequest: finalData[0].TotalLoanRequest,
              SourceOfDownPayment: finalData[0].SourceOfDownPayment,
              SubTotal: finalData[0].SubTotal,
              ClosingCostsPercentage: finalData[0].ClosingCostsPercentage,
              SBAGuaranteeFees: finalData[0].SBAGuaranteeFees,
              SBAGuaranteeFeesPercentage:
                finalData[0].SBAGuaranteeFeesPercentage,
              PersonalFunds: finalData[0].PersonalFunds,
              OthersInvestment: finalData[0].OthersInvestment,
              DownPaymentOthers: finalData[0].DownPaymentOthers,
              EquityPercentage: finalData[0].EquityPercentage,
              TotalAmount: finalData[0].TotalAmount,
              EquityDeal: finalData[0].EquityDeal,
              EquityClosing: finalData[0].EquityClosing,
              EquityLoanAmount: finalData[0].EquityLoanAmount,
              TotalEquityAmount: finalData[0].TotalEquityAmount,
              TermYear: finalData[0].TermYear,
              EmiStartDate: formatDate(finalData[0].EmiStartDate),
              InterestRate: finalData[0].Rate,
              TermYear: finalData[0].Terms
            });

            frmAmortization.patchValue({
              PurchaseRealProperty: finalData[0].PurchaseRealProperty,
              PurchaseImprovements: finalData[0].PurchaseImprovements,
              PurchaseEquipment: finalData[0].PurchaseEquipment,
              PurchaseFurnitureAndFixtures:
                finalData[0].PurchaseFurnitureAndFixtures,
              PurchaseInventory: finalData[0].PurchaseInventory,
              DebtRefinance: finalData[0].DebtRefinance,
              BusinessAcquisition: finalData[0].BusinessAcquisition,
              WorkingCapital: finalData[0].WorkingCapital,
              ClosingCosts: finalData[0].ClosingCosts,
              LessBorrowerDownPayment: finalData[0].LessBorrowerDownPayment,
              TotalLoanRequest: finalData[0].TotalLoanRequest,
              SourceOfDownPayment: finalData[0].SourceOfDownPayment,
              SubTotal: finalData[0].SubTotal,
              ClosingCostsPercentage: finalData[0].ClosingCostsPercentage,
              SBAGuaranteeFees: finalData[0].SBAGuaranteeFees,
              SBAGuaranteeFeesPercentage:
                finalData[0].SBAGuaranteeFeesPercentage,
              PersonalFunds: finalData[0].PersonalFunds,
              OthersInvestment: finalData[0].OthersInvestment,
              DownPaymentOthers: finalData[0].DownPaymentOthers,
              EquityPercentage: finalData[0].EquityPercentage,
              TotalAmount: finalData[0].TotalAmount,
              EquityDeal: finalData[0].EquityDeal,
              EquityClosing: finalData[0].EquityClosing,
              EquityLoanAmount: finalData[0].EquityLoanAmount,
              TotalEquityAmount: finalData[0].TotalEquityAmount
            });

            setLoading(false);
          }
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (AmortizationForm.status !== "INVALID") {
      setLoading(true);

      let finalObj = {
        ...AmortizationForm.value,
        CustID: loanId,
        ID: 0,
        LoanAmountID: 0,
        SessionId: getSessionId(),
        EmiStartDate: AmortizationForm.value.EmiStartDate,
      };

      InsertUpdateAmortization(finalObj)
        .then((data) => {
          if (data.status == 200) {
            setLoading(false);

            dispatch(
              reducersAndActions.actions.toasterActions.showToaster({
                visible: true,
                message: data.message,
                type: "success",
              })
            );
            setTimeout(
              () =>
                dispatch(
                  reducersAndActions.actions.toasterActions.hideToaster()
                ),
              Constants.TOASTERHIDETIME
            );
            getAmortizationInfo();
          }
          else {
            setLoading(false);
            dispatch(
              reducersAndActions.actions.toasterActions.showToaster({
                visible: true,
                message: data.message,
                type: "error",
              })
            );
            setTimeout(
              () =>
                dispatch(
                  reducersAndActions.actions.toasterActions.hideToaster()
                ),
              Constants.TOASTERHIDETIME
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          dispatch(
            reducersAndActions.actions.toasterActions.showToaster({
              visible: true,
              message: err.message,
              type: "error",
            })
          );
          setTimeout(
            () =>
              dispatch(reducersAndActions.actions.toasterActions.hideToaster()),
            Constants.TOASTERHIDETIME
          );
        });
    } else {
      markAllAsTouched(AmortizationForm);
      setLoading(false);
    }
  };

  // Marking all input fields as touched if the form is not valid
  const markAllAsTouched = (formGroup) => {
    Object.keys(formGroup.controls).forEach((controlName) => {
      const control = formGroup.get(controlName);
      if (control) {
        control.active = true;
        control.touched = true;
        if (document.getElementById(controlName)) {
          document.getElementById(controlName).focus();
        }
      }
    });
  };

  return (
    <>
      {/* SidePanel */}
      <SidePanel />

      {/* ProfileHeader */}
      <ProfileHeader />

      {loading ? (
        <Preloader />
      ) : (
        <div>
          <div className="lm-form-box pb-5">
            <FieldGroup
              control={AmortizationForm}
              render={({ get, invalid }) => (
                <form action="">
                  <div className="lm-card">
                    <h3 className="lm-card-heading">General information</h3>
                    <div className="row">
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group">
                          {/* <FormField
                            name="TermYear"
                            label="Term (years)"
                            placeholder="Enter Term (years)"
                            id="TermYear"
                            required={true}
                            errorMessage={"Term (years) is required"}
                          />
                           */}
                          <FieldControl
                            name="TermYear"
                            render={({ handler, touched, hasError, meta }) => {
                              const handlerProps = handler();
                              const handleChange = (e) => {
                                const rawValue = e.target.value.replace(
                                  /,/g,
                                  ""
                                ); // Remove existing commas

                                e.target.value = rawValue;
                                if (handlerProps.onChange) {
                                  handlerProps.onChange(e);
                                }
                              };

                              return (
                                <div className="form-group">
                                  <label htmlFor="TermYear">
                                    Term (years)
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="TermYear"
                                    placeholder="4"
                                    {...handlerProps}
                                    onChange={handleChange}
                                    name="TermYear"
                                    maxlength={3}
                                  />

                                  {/* Error messages below */}
                                  {touched && hasError("required") && <><span className="error-message">Term (years) is required</span><br /></>}
                                  {touched && hasError("pattern") && (
                                    <>
                                      <span className="error-message">
                                        Term Year should be only numeric values
                                      </span>
                                      <br />
                                    </>
                                  )}
                                </div>
                              );
                            }}
                            meta={{ placeholder: "400,000.00" }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group input-percentage">
                          <FieldControl
                            name="InterestRate"
                            render={({ handler, touched, hasError, meta }) => {
                              const handlerProps = handler();
                              const handleChange = (e) => {
                                const rawValue = e.target.value.replace(
                                  /,/g,
                                  ""
                                ); // Remove existing commas

                                e.target.value = rawValue;
                                if (handlerProps.onChange) {
                                  handlerProps.onChange(e);
                                }
                              };

                              return (
                                <div className="form-group input-dollar">
                                  <label htmlFor="Purchase_Real_Property">
                                    Interest Rate
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="InterestRate"
                                    placeholder="4"
                                    {...handlerProps}
                                    onChange={handleChange}
                                    name="InterestRate"
                                    maxlength={3}
                                  />

                                  {/* Error messages below */}
                                  {touched && hasError("required") && <><span className="error-message">Interest Rate is required</span><br /></>}
                                  {touched && hasError("pattern") && (
                                    <>
                                      <span className="error-message">
                                        Interest Rate should be only numeric values
                                      </span>
                                      <br />
                                    </>
                                  )}
                                </div>
                              );
                            }}
                            meta={{ placeholder: "400,000.00" }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group input-date">
                          <FieldControl
                            name="EmiStartDate"
                            render={({ handler, touched, hasError, meta }) => (
                              <div className="form-group input-date">
                                <label htmlFor="First_Payment_Date">
                                  First Payment Date
                                </label>
                                <DatePicker
                                  showIcon
                                  toggleCalendarOnIconClick
                                  {...handler()}
                                  selected={AmortizationForm.value.EmiStartDate}
                                  onChange={(date) => {
                                    AmortizationForm.patchValue({
                                      EmiStartDate: date,
                                    });
                                  }}
                                  isClearable={true}
                                  id="EmiStartDate"
                                  dateFormat="MM/d/yyyy"
                                  placeholderText="MM/DD/YYYY"
                                  yearDropdownItemNumber={100}
                                  scrollableYearDropdown
                                  showMonthDropdown
                                  showYearDropdown
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lm-card">
                    <h3 className="lm-card-heading">Uses</h3>
                    <div className="row">
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group input-dollar">
                          <FieldControl
                            name="PurchaseRealProperty"
                            render={({ handler, touched, hasError, meta }) => {
                              const handlerProps = handler();
                              const handleChange = (e) => {
                                const rawValue = e.target.value.replace(
                                  /,/g,
                                  ""
                                ); // Remove existing commas
                                const formattedValue =
                                  formatNumberWithCommasAmount(rawValue);
                                e.target.value = formattedValue;
                                // Logic for calculation
                                handleCalculateLoanAmount(
                                  rawValue,
                                  "PurchaseRealProperty",
                                  e
                                );
                                if (handlerProps.onChange) {
                                  handlerProps.onChange(e);
                                }
                              };

                              return (
                                <div className="form-group input-dollar">
                                  <label htmlFor="Purchase_Real_Property">
                                    Purchase Real Property
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="PurchaseRealProperty"
                                    placeholder="400,000.00"
                                    {...handlerProps}
                                    onChange={handleChange}
                                    value={handlerProps.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    name="PurchaseRealProperty"
                                    maxLength={15}
                                  />

                                  {/* Error messages below */}
                                  {touched && hasError("pattern") && (
                                    <>
                                      <span className="error-message">
                                        Amount should be only numeric values
                                      </span>
                                      <br />
                                    </>
                                  )}
                                </div>
                              );
                            }}
                            meta={{ placeholder: "400,000.00" }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group input-dollar">
                          <FieldControl
                            name="PurchaseImprovements"
                            render={({ handler, touched, hasError, meta }) => {
                              const handlerProps = handler();
                              const handleChange = (e) => {
                                const rawValue = e.target.value.replace(
                                  /,/g,
                                  ""
                                ); // Remove existing commas

                                const formattedValue =
                                  formatNumberWithCommasAmount(rawValue);
                                e.target.value = formattedValue;
                                // Logic for calculation
                                handleCalculateLoanAmount(
                                  rawValue,
                                  "PurchaseImprovements",
                                  e
                                );
                                if (handlerProps.onChange) {
                                  handlerProps.onChange(e);
                                }
                              };

                              return (
                                <div className="form-group input-dollar">
                                  <label htmlFor="Property_Improvement">
                                    Property Improvement
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="PurchaseImprovements"
                                    placeholder="000,000.00"
                                    {...handlerProps}
                                    onChange={handleChange}
                                    value={handlerProps.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    name="PurchaseImprovements"
                                    maxLength={15}
                                  />

                                  {/* Error messages below */}
                                  {touched && hasError("pattern") && (
                                    <>
                                      <span className="error-message">
                                        Amount should be only numeric values
                                      </span>
                                      <br />
                                    </>
                                  )}
                                </div>
                              );
                            }}
                            meta={{ placeholder: "400,000.00" }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group input-dollar">
                          <FieldControl
                            name="PurchaseEquipment"
                            render={({ handler, touched, hasError, meta }) => {
                              const handlerProps = handler();
                              const handleChange = (e) => {
                                const rawValue = e.target.value.replace(
                                  /,/g,
                                  ""
                                ); // Remove existing commas

                                const formattedValue =
                                  formatNumberWithCommasAmount(rawValue);
                                e.target.value = formattedValue;
                                // Logic for calculation
                                handleCalculateLoanAmount(
                                  rawValue,
                                  "PurchaseEquipment",
                                  e
                                );
                                if (handlerProps.onChange) {
                                  handlerProps.onChange(e);
                                }
                              };

                              return (
                                <div className="form-group input-dollar">
                                  <label htmlFor="Purchase_Furniture_Equipment">
                                    Purchase Equipment
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="PurchaseEquipment"
                                    placeholder="000,000.00"
                                    {...handlerProps}
                                    onChange={handleChange}
                                    value={handlerProps.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    name="PurchaseEquipment"
                                    maxLength={15}
                                  />

                                  {/* Error messages below */}
                                  {touched && hasError("pattern") && (
                                    <>
                                      <span className="error-message">
                                        Amount should be only numeric values
                                      </span>
                                      <br />
                                    </>
                                  )}
                                </div>
                              );
                            }}
                            meta={{ placeholder: "400,000.00" }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group input-dollar">
                          <FieldControl
                            name="PurchaseFurnitureAndFixtures"
                            render={({ handler, touched, hasError, meta }) => {
                              const handlerProps = handler();
                              const handleChange = (e) => {
                                const rawValue = e.target.value.replace(
                                  /,/g,
                                  ""
                                ); // Remove existing commas

                                const formattedValue =
                                  formatNumberWithCommasAmount(rawValue);
                                e.target.value = formattedValue;
                                // Logic for calculation
                                handleCalculateLoanAmount(
                                  rawValue,
                                  "PurchaseFurniture",
                                  e
                                );
                                if (handlerProps.onChange) {
                                  handlerProps.onChange(e);
                                }
                              };

                              return (
                                <div className="form-group input-dollar">
                                  <label htmlFor="Purchase_Furniture">
                                    Purchase Furniture
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="PurchaseFurnitureAndFixtures"
                                    placeholder="000,000.00"
                                    {...handlerProps}
                                    onChange={handleChange}
                                    name="PurchaseFurniture"
                                    maxLength={15}
                                  />

                                  {/* Error messages below */}
                                  {touched && hasError("pattern") && (
                                    <>
                                      <span className="error-message">
                                        Amount should be only numeric values
                                      </span>
                                      <br />
                                    </>
                                  )}
                                </div>
                              );
                            }}
                            meta={{ placeholder: "400,000.00" }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group input-dollar">
                          <FieldControl
                            name="PurchaseInventory"
                            render={({ handler, touched, hasError, meta }) => {
                              const handlerProps = handler();
                              const handleChange = (e) => {
                                const rawValue = e.target.value.replace(
                                  /,/g,
                                  ""
                                ); // Remove existing commas

                                const formattedValue =
                                  formatNumberWithCommasAmount(rawValue);
                                e.target.value = formattedValue;
                                // Logic for calculation
                                handleCalculateLoanAmount(
                                  rawValue,
                                  "PurchaseInventory",
                                  e
                                );
                                if (handlerProps.onChange) {
                                  handlerProps.onChange(e);
                                }
                              };

                              return (
                                <div className="form-group input-dollar">
                                  <label htmlFor="Purchase_Inventory">
                                    Purchase Inventory
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="PurchaseInventory"
                                    placeholder="000,000.00"
                                    {...handlerProps}
                                    onChange={handleChange}
                                    value={handlerProps.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    name="PurchaseInventory"
                                    maxLength={15}
                                  />

                                  {/* Error messages below */}
                                  {touched && hasError("pattern") && (
                                    <>
                                      <span className="error-message">
                                        Amount should be only numeric values
                                      </span>
                                      <br />
                                    </>
                                  )}
                                </div>
                              );
                            }}
                            meta={{ placeholder: "400,000.00" }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group input-dollar">
                          <FieldControl
                            name="DebtRefinance"
                            render={({ handler, touched, hasError, meta }) => {
                              const handlerProps = handler();
                              const handleChange = (e) => {
                                const rawValue = e.target.value.replace(
                                  /,/g,
                                  ""
                                ); // Remove existing commas

                                const formattedValue =
                                  formatNumberWithCommasAmount(rawValue);
                                e.target.value = formattedValue;
                                // Logic for calculation
                                handleCalculateLoanAmount(
                                  rawValue,
                                  "DebtRefinance",
                                  e
                                );
                                if (handlerProps.onChange) {
                                  handlerProps.onChange(e);
                                }
                              };

                              return (
                                <div className="form-group input-dollar">
                                  <label htmlFor="Debt_Refinance">
                                    Debt Refinance
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="DebtRefinance"
                                    placeholder="000,000.00"
                                    {...handlerProps}
                                    onChange={handleChange}
                                    value={handlerProps.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    name="DebtRefinance"
                                    maxLength={15}
                                  />

                                  {/* Error messages below */}
                                  {touched && hasError("pattern") && (
                                    <>
                                      <span className="error-message">
                                        Amount should be only numeric values
                                      </span>
                                      <br />
                                    </>
                                  )}
                                </div>
                              );
                            }}
                            meta={{ placeholder: "400,000.00" }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group input-dollar">
                          <FieldControl
                            name="BusinessAcquisition"
                            render={({ handler, touched, hasError, meta }) => {
                              const handlerProps = handler();
                              const handleChange = (e) => {
                                const rawValue = e.target.value.replace(
                                  /,/g,
                                  ""
                                ); // Remove existing commas

                                const formattedValue =
                                  formatNumberWithCommasAmount(rawValue);
                                e.target.value = formattedValue;
                                // Logic for calculation
                                handleCalculateLoanAmount(
                                  rawValue,
                                  "BusinessAcquisition",
                                  e
                                );
                                if (handlerProps.onChange) {
                                  handlerProps.onChange(e);
                                }
                              };

                              return (
                                <div className="form-group input-dollar">
                                  <label htmlFor="Business_Acquisition">
                                    Business Acquisition
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="BusinessAcquisition"
                                    placeholder="000,000.00"
                                    {...handlerProps}
                                    onChange={handleChange}
                                    value={handlerProps.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    name="BusinessAcquisition"
                                    maxLength={15}
                                  />

                                  {/* Error messages below */}
                                  {touched && hasError("pattern") && (
                                    <>
                                      <span className="error-message">
                                        Amount should be only numeric values
                                      </span>
                                      <br />
                                    </>
                                  )}
                                </div>
                              );
                            }}
                            meta={{ placeholder: "400,000.00" }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group input-dollar">
                          <FieldControl
                            name="WorkingCapital"
                            render={({ handler, touched, hasError, meta }) => {
                              const handlerProps = handler();
                              const handleChange = (e) => {
                                const rawValue = e.target.value.replace(
                                  /,/g,
                                  ""
                                ); // Remove existing commas

                                const formattedValue =
                                  formatNumberWithCommasAmount(rawValue);
                                e.target.value = formattedValue;
                                // Logic for calculation
                                handleCalculateLoanAmount(
                                  rawValue,
                                  "WorkingCapital",
                                  e
                                );
                                if (handlerProps.onChange) {
                                  handlerProps.onChange(e);
                                }
                              };

                              return (
                                <div className="form-group input-dollar">
                                  <label htmlFor="Working_Capital">
                                    Working Capital
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="WorkingCapital"
                                    placeholder="000,000.00"
                                    {...handlerProps}
                                    onChange={handleChange}
                                    value={handlerProps.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    name="WorkingCapital"
                                    maxLength={15}
                                  />

                                  {/* Error messages below */}
                                  {touched && hasError("pattern") && (
                                    <>
                                      <span className="error-message">
                                        Amount should be only numeric values
                                      </span>
                                      <br />
                                    </>
                                  )}
                                </div>
                              );
                            }}
                            meta={{ placeholder: "400,000.00" }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group input-dollar">
                          <FieldControl
                            name="OthersInvestment"
                            render={({ handler, touched, hasError, meta }) => {
                              const handlerProps = handler();
                              const handleChange = (e) => {
                                const rawValue = e.target.value.replace(
                                  /,/g,
                                  ""
                                ); // Remove existing commas

                                const formattedValue =
                                  formatNumberWithCommasAmount(rawValue);
                                e.target.value = formattedValue;
                                // Logic for calculation
                                handleCalculateLoanAmount(
                                  rawValue,
                                  "OthersInvestment",
                                  e
                                );
                                if (handlerProps.onChange) {
                                  handlerProps.onChange(e);
                                }
                              };

                              return (
                                <div className="form-group input-dollar">
                                  <label htmlFor="Others">Others</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="OthersInvestment"
                                    placeholder="000,000.00"
                                    {...handlerProps}
                                    onChange={handleChange}
                                    value={handlerProps.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    name="OthersInvestment"
                                    maxLength={15}
                                  />

                                  {/* Error messages below */}
                                  {touched && hasError("pattern") && (
                                    <>
                                      <span className="error-message">
                                        Amount should be only numeric values
                                      </span>
                                      <br />
                                    </>
                                  )}
                                </div>
                              );
                            }}
                            meta={{ placeholder: "400,000.00" }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group input-dollar">
                          <FieldControl
                            name="SubTotal"
                            render={({ handler, touched, hasError, meta }) => {
                              const handlerProps = handler();
                              const handleChange = (e) => {
                                const rawValue = e.target.value.replace(
                                  /,/g,
                                  ""
                                ); // Remove existing commas
                                const formattedValue =
                                  formatNumberWithCommasAmount(rawValue);
                                e.target.value = formattedValue;
                                if (handlerProps.onChange) {
                                  handlerProps.onChange(e);
                                }
                              };

                              return (
                                <div className="form-group input-dollar">
                                  <label htmlFor="SubTotal">Sub Total</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="SubTotal"
                                    placeholder="000,000.00"
                                    value={handlerProps.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    // onChange={handleChange}
                                    // {...handlerProps}
                                    readOnly
                                  />

                                  {/* Error messages below */}
                                  {touched && hasError("pattern") && (
                                    <>
                                      <span className="error-message">
                                        Amount should be only numeric values
                                      </span>
                                      <br />
                                    </>
                                  )}
                                </div>
                              );
                            }}
                            meta={{ placeholder: "400,000.00" }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group"></div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group"></div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group input-percentage">
                          <FieldControl
                            name="ClosingCostsPercentage"
                            render={({ handler, touched, hasError, meta }) => {
                              const handlerProps = handler();
                              const handleChange = (e) => {
                                const rawValue = e.target.value.replace(
                                  /,/g,
                                  ""
                                ); // Remove existing commas

                                e.target.value = rawValue;
                                // Logic for calculation
                                handleCalculateLoanAmount(
                                  rawValue,
                                  "ClosingCostsPercentage",
                                  e
                                );
                                if (handlerProps.onChange) {
                                  handlerProps.onChange(e);
                                }
                              };

                              return (
                                <div className="form-group input-dollar">
                                  <label htmlFor="Closing_Cost_Per">
                                    Closing Cost (%)
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="ClosingCostsPercentage"
                                    placeholder="000,000.00"
                                    {...handlerProps}
                                    name="ClosingCostsPercentage"
                                    onChange={handleChange}
                                    maxLength={3}
                                  />

                                  {/* Error messages below */}
                                  {touched && hasError("pattern") && (
                                    <>
                                      <span className="error-message">
                                        Amount should be only numeric values
                                      </span>
                                      <br />
                                    </>
                                  )}
                                </div>
                              );
                            }}
                            meta={{ placeholder: "400,000.00" }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group input-dollar">
                          <FieldControl
                            name="ClosingCosts"
                            render={({ handler, touched, hasError, meta }) => {
                              const handlerProps = handler();
                              const handleChange = (e) => {
                                const rawValue = e.target.value.replace(
                                  /,/g,
                                  ""
                                ); // Remove existing commas
                                const formattedValue =
                                  formatNumberWithCommasAmount(rawValue);
                                e.target.value = formattedValue;
                                if (handlerProps.onChange) {
                                  handlerProps.onChange(e);
                                }
                              };

                              return (
                                <div className="form-group input-dollar">
                                  <label htmlFor="Closing_Cost_dol">
                                    Closing Cost ($)
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="ClosingCosts"
                                    placeholder="000,000.00"
                                    {...handlerProps}
                                    onChange={handleChange}
                                    value={handlerProps.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    readOnly
                                  />

                                  {/* Error messages below */}
                                  {touched && hasError("pattern") && (
                                    <>
                                      <span className="error-message">
                                        Amount should be only numeric values
                                      </span>
                                      <br />
                                    </>
                                  )}
                                </div>
                              );
                            }}
                            meta={{ placeholder: "400,000.00" }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group input-dollar">
                          <FieldControl
                            name="SBAGuaranteeFeesPercentage"
                            render={({ handler, touched, hasError, meta }) => {
                              const handlerProps = handler();
                              const handleChange = (e) => {
                                const rawValue = e.target.value.replace(
                                  /,/g,
                                  ""
                                ); // Remove existing commas
                                // const formattedValue =
                                //   formatNumberWithCommasAmount(rawValue);
                                e.target.value = rawValue;
                                // Logic for calculation
                                handleCalculateLoanAmount(
                                  rawValue,
                                  "SBAGuaranteeFeesPercentage",
                                  e
                                );
                                if (handlerProps.onChange) {
                                  handlerProps.onChange(e);
                                }
                              };

                              return (
                                <div className="form-group input-dollar">
                                  <label htmlFor="SBA_Guarantee_Fee_Per">
                                    SBA Guarantee Fee (%)
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="SBAGuaranteeFeesPercentage"
                                    name="SBAGuaranteeFeesPercentage"
                                    placeholder="00.00"
                                    {...handlerProps}
                                    onChange={handleChange}
                                    maxLength={3}
                                  />

                                  {/* Error messages below */}
                                  {touched && hasError("pattern") && (
                                    <>
                                      <span className="error-message">
                                        Amount should be only numeric values
                                      </span>
                                      <br />
                                    </>
                                  )}
                                </div>
                              );
                            }}
                            meta={{ placeholder: "00.00" }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group input-percentage">
                          <FieldControl
                            name="SBAGuaranteeFees"
                            render={({ handler, touched, hasError, meta }) => {
                              const handlerProps = handler();
                              const handleChange = (e) => {
                                const rawValue = e.target.value.replace(
                                  /,/g,
                                  ""
                                ); // Remove existing commas
                                // const formattedValue =
                                //   formatNumberWithCommasAmount(rawValue);
                                e.target.value = rawValue;
                                if (handlerProps.onChange) {
                                  handlerProps.onChange(e);
                                }
                              };

                              return (
                                <div className="form-group input-dollar">
                                  <label htmlFor="SBA_Guarantee_Fee_dol">
                                    SBA Guarantee Fee ($)
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="SBAGuaranteeFees"
                                    placeholder="00.00"
                                    {...handlerProps}
                                    onChange={handleChange}
                                    value={handlerProps.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    readOnly
                                  />

                                  {/* Error messages below */}
                                  {touched && hasError("pattern") && (
                                    <>
                                      <span className="error-message">
                                        Amount should be only numeric values
                                      </span>
                                      <br />
                                    </>
                                  )}
                                </div>
                              );
                            }}
                            meta={{ placeholder: "00.00" }}
                          />
                        </div>
                      </div>

                      {/* <!-- lm common divider to break the section  --> */}
                      <div className="lm-divider my-3 mx-3"></div>
                      {/* <!-- lm common divider to break the section  --> */}

                      <div className="col-12 d-flex justify-content-between">
                        <span className="total-label">Total Cost</span>
                        <h3 className="total-amount">
                          ${AmortizationForm.value.TotalAmount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="lm-card">
                    <h3 className="lm-card-heading">Sources</h3>
                    <div className="row">
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group input-percentage">
                          <FieldControl
                            name="EquityPercentage"
                            render={({ handler, touched, hasError, meta }) => {
                              const handlerProps = handler();
                              const handleChange = (e) => {
                                const rawValue = e.target.value.replace(
                                  /,/g,
                                  ""
                                ); // Remove existing commas
                                // const formattedValue =
                                //   formatNumberWithCommasAmount(rawValue);
                                e.target.value = rawValue;
                                // Logic for calculation
                                handleCalculateLoanAmount(
                                  rawValue,
                                  "EquityPercentage",
                                  e
                                );
                                if (handlerProps.onChange) {
                                  handlerProps.onChange(e);
                                }
                              };

                              return (
                                <div className="form-group input-percentage">
                                  <label htmlFor="Required_Equity_per">
                                    Required Equity (%)
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="EquityPercentage"
                                    placeholder="000,000.00"
                                    {...handlerProps}
                                    onChange={handleChange}
                                    name="EquityPercentage"
                                    maxLength={3}
                                  />

                                  {/* Error messages below */}
                                  {touched && hasError("pattern") && (
                                    <>
                                      <span className="error-message">
                                        Amount should be only numeric values
                                      </span>
                                      <br />
                                    </>
                                  )}
                                </div>
                              );
                            }}
                            meta={{ placeholder: "400,000.00" }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group input-dollar">
                          <FieldControl
                            name="EquityDeal"
                            render={({ handler, touched, hasError, meta }) => {
                              const handlerProps = handler();
                              const handleChange = (e) => {
                                const rawValue = e.target.value.replace(
                                  /,/g,
                                  ""
                                ); // Remove existing commas
                                const formattedValue =
                                  formatNumberWithCommasAmount(rawValue);
                                e.target.value = formattedValue;
                                if (handlerProps.onChange) {
                                  handlerProps.onChange(e);
                                }
                              };

                              return (
                                <div className="form-group input-dollor">
                                  <label htmlFor="Required_Equity_Dol">
                                    Required Equity ($)
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="EquityDeal"
                                    placeholder="000,000.00"
                                    {...handlerProps}
                                    onChange={handleChange}
                                    value={handlerProps.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    readOnly
                                  />

                                  {/* Error messages below */}
                                  {touched && hasError("pattern") && (
                                    <>
                                      <span className="error-message">
                                        Amount should be only numeric values
                                      </span>
                                      <br />
                                    </>
                                  )}
                                </div>
                              );
                            }}
                            meta={{ placeholder: "400,000.00" }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group input-dollar">
                          <FieldControl
                            name="LessBorrowerDownPayment"
                            render={({ handler, touched, hasError, meta }) => {
                              const handlerProps = handler();
                              const handleChange = (e) => {
                                const rawValue = e.target.value.replace(
                                  /,/g,
                                  ""
                                ); // Remove existing commas
                                const formattedValue =
                                  formatNumberWithCommasAmount(rawValue);
                                e.target.value = formattedValue;
                                // Logic for calculation
                                handleCalculateLoanAmount(
                                  rawValue,
                                  "LessBorrowerDownPayment",
                                  e
                                );
                                if (handlerProps.onChange) {
                                  handlerProps.onChange(e);
                                }
                              };

                              return (
                                <div className="form-group input-dollar">
                                  <label htmlFor="Less_Down_Payment">
                                    Less: Down Payment ($)
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="LessBorrowerDownPayment"
                                    placeholder="00.00"
                                    {...handlerProps}
                                    onChange={handleChange}
                                    value={handlerProps.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    name="LessBorrowerDownPayment"
                                    maxLength={15}
                                  />

                                  {/* Error messages below */}
                                  {touched && hasError("pattern") && (
                                    <>
                                      <span className="error-message">
                                        Amount should be only numeric values
                                      </span>
                                      <br />
                                    </>
                                  )}
                                </div>
                              );
                            }}
                            meta={{ placeholder: "00.00" }}
                          />
                        </div>
                      </div>

                      {/* <!-- lm common divider to break the section  --> */}
                      <div className="lm-divider my-3 mx-3"></div>
                      {/* <!-- lm common divider to break the section  --> */}

                      <div className="col-12 d-flex justify-content-between mb-1">
                        <span className="sub-total-label text-light">
                          Capital Required for Equity
                        </span>
                        <h3 className="sub-total-amount">
                          ${AmortizationForm.value.EquityClosing?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </h3>
                      </div>
                      <div className="col-12 d-flex justify-content-between mb-1">
                        <span className="sub-total-label text-light">
                          Loan Amount
                        </span>
                        <h3 className="sub-total-amount">
                          ${AmortizationForm.value.EquityLoanAmount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </h3>
                      </div>
                      <div className="col-12 d-flex justify-content-between">
                        <span className="total-label">Total</span>
                        <h3 className="total-amount">
                          ${AmortizationForm.value.TotalEquityAmount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="sticky-btn-footer d-flex justify-content-end">
                    <button
                      type="button"
                      className="lm-button2"
                      onClick={() => setPreviousTabPopup(true)}
                    >
                      Back
                    </button>
                    <button
                      type="button"
                      className="lm-button1 ml-3"
                      onClick={handleSubmit}
                    >
                      Save
                    </button>
                  </div>
                </form>
              )}
            />
          </div>

          {/* <!--back to Previous Tab Popup / Modal --> */}
          <Modal
            className="mark-comp-popup-cust-size"
            show={PreviousTabPopupShow}
            onHide={() => setPreviousTabPopup(false)}
            aria-labelledby="PreviousTabPopupLabel"
            backdrop="static"
            keyboard={false}
            centered
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-body signout-modal">
                  <h3 className="pt-4">
                    Going back will lead to the loss of unsaved data
                  </h3>
                </div>
                <div className="modal-footer la-app-modal-footer">
                  <button
                    type="button"
                    className="lm-button2"
                    onClick={PreviousTabPopupClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="lm-button1"
                    onClick={backPreviousTab}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          {/* <!--back to Previous Tab Popup / Modal --> */}
        </div>
      )}
    </>
  );
}

export default Amortization;
