import { Link } from 'react-router-dom';
import './Header.css';
import React, { useState } from 'react';
import lm_logo from '../../assets/img/lm_logo.svg';
import colse_menu_icon from '../../assets/img/colse_menu_icon.svg';
import angle_down from '../../assets/img/angle_down.svg';
import ser_hero_bg from '../../assets/img/ser_hero_bg.svg';
import menu_icon from '../../assets/img/menu_icon.svg';

import SBA_Loans from '../../assets/img/mega_menu_icons/SBA_Loans.svg';
import Equipment_Loans from '../../assets/img/mega_menu_icons/Equipment_Loans.svg';
import Gas_Stations from '../../assets/img/mega_menu_icons/Gas_Stations.svg';
import Hospitality from '../../assets/img/mega_menu_icons/Hospitality.svg';
import Real_Estate from '../../assets/img/mega_menu_icons/Real_Estate.svg';
import Medical from '../../assets/img/mega_menu_icons/Medical.svg';
import Franchise_QSR from '../../assets/img/mega_menu_icons/Franchise&QSR.svg';
import Transportation from '../../assets/img/mega_menu_icons/Transportation.svg';
import Startups from '../../assets/img/mega_menu_icons/Startups.svg';
import Business_Lines from '../../assets/img/mega_menu_icons/Business_Lines.svg';

import Knowledge_Base from '../../assets/img/mega_menu_icons/Knowledge_Base.svg';
import Loan_Calculator from '../../assets/img/mega_menu_icons/Loan_Calculator.svg';
import SMB_Checklist from '../../assets/img/mega_menu_icons/SMB_Checklist.svg';
import State_Index from '../../assets/img/mega_menu_icons/State_Index.svg';
import News_and_Media from '../../assets/img/mega_menu_icons/News_and_Media.svg';

function Header() {
  const [showServiceMenu, setshowServiceMenu] = useState(false);
  const [showSideMenu, setshowSideMenu] = useState(false);
  const [showResourceMenu, setshowResourceMenu] = useState(false);
  const [ServiceButtonClass, setServiceButtonClass] = useState(''); // Initially no class
  const [ResourceButtonClass, setResourceButtonClass] = useState(''); // Initially no class
  const [HamBurgerClass, setHamBurgerClass] = useState(''); // Initially no class

  const toggle_service_mega_menu = () => {
    setshowServiceMenu(!showServiceMenu);
    setshowResourceMenu(false);
    setServiceButtonClass(ServiceButtonClass === 'active-menu-item' ? '' : 'active-menu-item'); // Toggle the class
  };

  const toggle_Resource_mega_menu = () => {
    setshowResourceMenu(!showResourceMenu);
    setshowServiceMenu(false)
    setResourceButtonClass(ResourceButtonClass === 'active-menu-item' ? '' : 'active-menu-item'); // Toggle the class
  };

  const toggle_side_menu = () => {
    setshowSideMenu(!showSideMenu);
    setshowResourceMenu(false);
    setshowServiceMenu(false);
    setHamBurgerClass(HamBurgerClass === 'active-hamburger' ? '' : 'active-hamburger'); // Toggle the class
    setServiceButtonClass(''); // Remove the class
    setResourceButtonClass(''); // Remove the class
  };


  return (
    <div>
      <header className="website-header">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-5 place-content-center">
              <div className="logo">
                <a href='https://loanmantra.com/'><img src={lm_logo} alt="" /></a>
              </div>
            </div>
            <div className="col-md-6 col-2 place-content-center">
              <div className="d-lg-flex d-none justify-content-center">
                <a className="menu-item py-3 service_btn" href="#">
                  Services
                  <img className="megamenu-dropdown" src={angle_down} alt="" />
                  <div id="service_mega_menu" className="service-mega-menu">
                    <div className="container">
                      <div className="ser-mega-menu-hero">
                        <img src={ser_hero_bg} alt="" />
                        <h2>For Lenders</h2>
                        <a href="https://loanmantra.com/lender-page/">Read More</a>
                      </div>
                      <div className="ser-menu-item-grp">
                        <a href="https://loanmantra.com/service/sba-loans/" className="ser-menu-item">
                          <img src={SBA_Loans} alt="" />
                          SBA Loans
                        </a>
                        <a href="https://loanmantra.com/service/eequipment-loans/" className="ser-menu-item">
                          <img src={Equipment_Loans} alt="" />
                          Equipment Loans
                        </a>
                        <a href="https://loanmantra.com/service/gas-stations/" className="ser-menu-item">
                          <img src={Gas_Stations} alt="" />
                          Gas Stations
                        </a>
                        <a href="https://loanmantra.com/service/hospitality/" className="ser-menu-item">
                          <img src={Hospitality} alt="" />
                          Hospitality
                        </a>
                        <a href="https://loanmantra.com/service/commercial-real-estate/" className="ser-menu-item">
                          <img src={Real_Estate} alt="" />
                          Real Estate
                        </a>
                        <a href="https://loanmantra.com/service/medical/" className="ser-menu-item">
                          <img src={Medical} alt="" />
                          Medical
                        </a>
                        <a href="https://loanmantra.com/service/franchise-qsr/" className="ser-menu-item">
                          <img src={Franchise_QSR} alt="" />
                          Franchise & QSR
                        </a>
                        <a href="https://loanmantra.com/service/transportation/" className="ser-menu-item">
                          <img src={Transportation} alt="" />
                          Transportation
                        </a>
                        <a href="https://loanmantra.com/service/startups/" className="ser-menu-item">
                          <img src={Startups} alt="" />
                          Startups
                        </a>
                        <a href="https://loanmantra.com/service/business-lines/" className="ser-menu-item">
                          <img src={Business_Lines} alt="" />
                          Business Lines
                        </a>
                      </div>
                    </div>
                  </div>
                </a>

                <a className="menu-item py-3 res_btn dropdown" href="#">
                  Resources
                  <img className="megamenu-dropdown" src={angle_down} alt="" />
                  <div id="res_mega_menu" className="res-mega-menu">
                    <div className="container">
                      <div className="res-menu-item-grp">
                        <a href="https://loanmantra.com/knowledge-base/" className="res-menu-item">
                          <img src={Knowledge_Base} alt="" />
                          Knowledge Base
                        </a>
                        <a href="https://loanmantra.com/loan-calculator/" className="res-menu-item">
                          <img src={Loan_Calculator} alt="" />
                          Loan Calculator
                        </a>
                        <a href="https://loanmantra.com/checklist/" className="res-menu-item">
                          <img src={SMB_Checklist} alt="" />
                          SMB Checklist
                        </a>
                        <a href="https://loanmantra.com/state-index/" className="res-menu-item">
                          <img src={State_Index} alt="" />
                          State Index
                        </a>
                        <a href="https://loanmantra.com/news/" className="res-menu-item">
                          <img src={News_and_Media} alt="" />
                          News and Media
                        </a>
                      </div>
                    </div>
                  </div>
                </a>

                <a className="menu-item py-3" href="https://loanmantra.com/about/">
                  About
                </a>
                <a className="menu-item py-3" href="https://loanmantra.com/contact/">
                  Contact Us
                </a>
              </div>

            </div>
            <div className="col-md-3 col-5 d-flex justify-content-end">
              <div className="btn-group d-lg-flex d-none">
                <Link className="menu-item link-secondary login-btn-link" to="/">Log In</Link>
                <Link className="btn-register" to="/signup">Apply Now</Link>
              </div>
              <div className={`hamburger-menu d-lg-none d-block ${HamBurgerClass}`} onClick={toggle_side_menu}>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
              </div>
            </div>
          </div>
        </div>
      </header>

  
        <div className={`slide-menu ${showSideMenu ? 'slideRightIn' : 'slideLeftOut'}`}>
          <div className="slide-menu-scroll">
            <div className={`menu-item py-3 service_btn ${ServiceButtonClass}`} onClick={toggle_service_mega_menu}>
              Services
              <img className="megamenu-dropdown" src={angle_down} alt="" />
              {showServiceMenu &&
                <div id="service_mega_menu" className="service-mega-menu">
                  <div className="container">
                    <div className="ser-mega-menu-hero">
                      <h2>For Lenders</h2>
                      <a href="https://loanmantra.com/lender-page/">Read More</a>
                    </div>
                    <div className="ser-menu-item-grp">
                      <a href="https://loanmantra.com/service/sba-loans/" className="ser-menu-item">
                        <img src={SBA_Loans} alt="" />
                        SBA Loans
                      </a>
                      <a href="https://loanmantra.com/service/eequipment-loans/" className="ser-menu-item">
                        <img src={Equipment_Loans} alt="" />
                        Equipment Loans
                      </a>
                      <a href="https://loanmantra.com/service/gas-stations/" className="ser-menu-item">
                        <img src={Gas_Stations} alt="" />
                        Gas Stations
                      </a>
                      <a href="https://loanmantra.com/service/hospitality/" className="ser-menu-item">
                        <img src={Hospitality} alt="" />
                        Hospitality
                      </a>
                      <a href="https://loanmantra.com/service/commercial-real-estate/" className="ser-menu-item">
                        <img src={Real_Estate} alt="" />
                        Real Estate
                      </a>
                      <a href="https://loanmantra.com/service/medical/" className="ser-menu-item">
                        <img src={Medical} alt="" />
                        Medical
                      </a>
                      <a href="https://loanmantra.com/service/franchise-qsr/" className="ser-menu-item">
                        <img src={Franchise_QSR} alt="" />
                        Franchise & QSR
                      </a>
                      <a href="https://loanmantra.com/service/transportation/" className="ser-menu-item">
                        <img src={Transportation} alt="" />
                        Transportation
                      </a>
                      <a href="https://loanmantra.com/service/startups/" className="ser-menu-item">
                        <img src={Startups} alt="" />
                        Startups
                      </a>
                      <a href="https://loanmantra.com/service/business-lines/" className="ser-menu-item">
                        <img src={Business_Lines} alt="" />
                        Business Lines
                      </a>
                    </div>
                  </div>
                </div>
              }
            </div>

            <div className={`menu-item py-3 service_btn ${ResourceButtonClass}`} onClick={toggle_Resource_mega_menu}>
              Resources
              <img className="megamenu-dropdown" src={angle_down} alt="" />
              {showResourceMenu &&
                <div id="res_mega_menu" className="res-mega-menu">
                  <div className="container">
                    <div className="res-menu-item-grp">
                      <a href="https://loanmantra.com/knowledge-base/" className="res-menu-item">
                        <img src={Knowledge_Base} alt="" />
                        Knowledge Base
                      </a>
                      <a href="https://loanmantra.com/loan-calculator/" className="res-menu-item">
                        <img src={Loan_Calculator} alt="" />
                        Loan Calculator
                      </a>
                      <a href="https://loanmantra.com/checklist/" className="res-menu-item">
                        <img src={SMB_Checklist} alt="" />
                        SMB Checklist
                      </a>
                      <a href="https://loanmantra.com/state-index/" className="res-menu-item">
                        <img src={State_Index} alt="" />
                        State Index
                      </a>
                      <a href="https://loanmantra.com/news/" className="res-menu-item">
                        <img src={News_and_Media} alt="" />
                        News and Media
                      </a>
                    </div>
                  </div>
                </div>
              }

            </div>

            <div className="menu-item py-3">
              <a href="https://loanmantra.com/about/">
                About
              </a>
            </div>

            <div className="menu-item py-3">
              <a href="https://loanmantra.com/contact/">
                Contact Us
              </a>
            </div>

            <div className="btn-group d-block">
              <div className="text-center mb-4 mt-4">
                <Link className="menu-item link-secondary login-btn-link" to="/">Log In</Link>
              </div>
              <div className="text-center">
                <Link className="btn-register w-100" to="/signup">Apply Now</Link>
              </div>

            </div>
          </div>



        </div>
    </div>


  );
}

export default Header;
