import axios from "axios";
import Constants from "./Constants";
import { flushLocalStorageData, getLocalStorageData } from "../services/storageData";
import { logout } from "../services/commonService";

const instance = axios.create({
    baseURL: Constants.BACKEND_URL
});

instance.interceptors.request.use(
    function (config) {
        if (getLocalStorageData("token")) {
            let storageToken = getLocalStorageData("token");
            config.headers["Authorization"] = `Bearer ${storageToken}`;
        }
        // Set Content-Type based on data type
        if (config.data instanceof FormData) {
            config.headers["Content-Type"] = "multipart/form-data";
        } else {
            config.headers["Content-Type"] = "application/json";
        }
        return config;
    },
    function (err) {
        return Promise.reject(err);
    }
);

instance.interceptors.response.use(
    function (response) {
        // If the user is logged out, clear local storage and redirect
        if (response.data.status === 401 && response.data.message === "User Log out") {
            console.log("User Log out--> Function triggered from 1", response);
            flushLocalStorageData();
            window.location.href = "/";  // Use window.location for redirection
        }
        return response;
    },
    async function (error) {
        // If unauthorized error, perform logout and redirect
        if (error.response?.status === 401 && error.response.data === "") {
            console.log("User Log out--> Function triggered from 2", error);
            const email = getLocalStorageData("email");
            const userData = getLocalStorageData('profileData');
            const EmailAddress = JSON.parse(email) || JSON.parse(userData)?.email;
            await logout(EmailAddress);
            flushLocalStorageData();
            window.location.href = "/";  // Use window.location for redirection
        }
        return Promise.reject(error);
    }
);

export default instance;
