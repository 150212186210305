import axios from '../helpers/axiosConfig';
import { getSessionId } from './storageData';
import { getLocalStorageData } from './storageData';


export const InsertUpdateContractor = async (data) => {
    try {
        // Create an instance of axios
        const response = await axios.post('/api/LoanApplication/InsertUpdateContractor', data,
            {
                params: { SessionId: getSessionId() },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getLocalStorageData("token")}`
                }
            }
        );
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
};


// Insert  Hotel details
export const InsertUpdateHotel = async (data) => {
    try {
        const response = await axios.post('/api/LoanApplication/InsertUpdateHotel', data,
            {
                params: { SessionId: getSessionId() },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getLocalStorageData("token")}`
                }
            }
        );
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
};

// InsertUpdate  Restaurant
export const InsertUpdateRestaurant = async (data) => {
    try {
        const response = await axios.post('/api/LoanApplication/InsertUpdateRestaurant', data,
            {
                params: { SessionId: getSessionId() },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getLocalStorageData("token")}`
                }
            }
        );
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
};

// InsertUpdate  Medical
export const InsertUpdateMedical = async (data) => {
    try {
        const response = await axios.post('/api/LoanApplication/InsertUpdateMedical', data,
            {
                params: { SessionId: getSessionId() },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getLocalStorageData("token")}`
                }
            }
        );
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
};

// InsertUpdate  GasStation
export const InsertUpdateGasStation = async (data) => {
    try {
        const response = await axios.post('/api/LoanApplication/InsertUpdateGasStation', data,
            {
                params: { SessionId: getSessionId() },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getLocalStorageData("token")}`
                }
            }
        );
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
};


export const GetIndustryDetailsById = async (loanId, type) => {
    try {


        const response = await axios.get('/api/LoanApplication/GetIndustryDetailsById', {
            params: {
                LoanId: loanId,
                Type: type,
                SessionId: getSessionId()
            },
            headers: {
                'Authorization': `Bearer ${getLocalStorageData("token")}`
            }
        });

        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.error(err);
        throw err;
    }
};