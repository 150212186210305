import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { InsertUpdateHotel ,GetIndustryDetailsById } from '../../services/IndustriesServices'; // Import your service function

// Async thunk for fetching existing hotel data
export const fetchHotelData = createAsyncThunk('HotelForm/fetchHotelData', async ({ loanId, type }) => {
    const response = await GetIndustryDetailsById(loanId, type);
    return response;
});

// Async thunk for form submission (save and update)
// export const submitHotelForm = createAsyncThunk('HotelForm/submitHotelForm', async (formData) => {
//     const response = await InsertUpdateHotel(formData);
//     return response;
// });

export const submitHotelForm = createAsyncThunk('HotelForm/submitHotelForm', async (formData) => {
  const { id, ...restFormData } = formData;
  let response;

  if (id) {
      response = await InsertUpdateHotel(formData);
  } else {
      response = await InsertUpdateHotel(restFormData);
  }
  
  return response;
});

const hotelFormSlice = createSlice({
  name: 'HotelForm',
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {
    resetHotelFormState: (state) => {
      state.data = null;
      state.loading = false;
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHotelData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchHotelData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchHotelData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(submitHotelForm.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(submitHotelForm.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(submitHotelForm.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});


export const { resetHotelFormState } = hotelFormSlice.actions;
export default hotelFormSlice.reducer;
