import { createSlice } from '@reduxjs/toolkit'
import * as counterReducers from '@redux-local/reducers/counter'


const counterSlice = createSlice({
  name: 'counter',
  initialState: {
    value: 0
  },
  reducers: {
    ...counterReducers
  }
})

export default counterSlice.reducer
export const counterActions  = counterSlice.actions