import { createSlice } from '@reduxjs/toolkit';

const errorSlice = createSlice({
  name: 'errorbox',
  initialState: {
    visible: false,
    message: "test",
  },
  reducers: {
    showError: (state, user) => {
        state.visible = true;
        state.message = user.payload.message;
    },
    hideError: (state) => {
        state.visible = false;
        state.message = "";
    }
  }
});

export const errorActions = errorSlice.actions;
export default errorSlice.reducer;