import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal'
import { Link } from "react-router-dom";
import { SidePanel, ProfileHeader } from "@components";
import Preloader from '../../plugins/Preloader';
import edit_pen from '../../assets/icons/edit-pen.svg';
import email_icon from '../../assets/icons/email_icon.svg';
import { GetOwnersListofPFSById, SendOwnerEmailonPersonalFinance } from '../../services/loanApplicationServices';
import { getSessionId } from '../../services/storageData';
import { useNavigate } from 'react-router-dom';
import { encryptText } from '../../helpers/DynamicFunctions';
import { useDispatch } from 'react-redux';
import reducersAndActions from '../../_redux/slices';
import Constants from '../../helpers/Constants';
import { PFSApplicationList } from '../../helpers/DropdownsAndMappers';
import DynamicTable from '../../plugins/DynamicTable';


function PFS({ loanid, applicationNumber }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Meta Title
    useEffect(() => {
        document.title = "PFS - Loan Application";
    }, []);

    // Preloader Start
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(reducersAndActions.actions.sessionActions.setLoading(loading));
    }, [loading]);


    // Preloader End

    //Popup function
    const [sentEmailPopupShow, setSentEmailPopup] = useState(false);
    const sentEmailPopupClose = () => setSentEmailPopup(false);
    const [emailSentToItem, setEmailSentToItem] = useState({});

    const [loanId, setLoanId] = useState(loanid);
    const [pfsAPplicationList, setPfsAPplicationList] = useState([]);
    const [totalDataPCF, setTotalDataPCF] = useState(0);
    const [inquiriesPCF, setInquiriesPCF] = useState([]);
    const [pageIndexPCF, setPageIndexPCF] = useState(1);
    const [pageSizePCF, setPageSizePCF] = useState(10);

    useEffect(() => {
        fetchListData();
    }, [pageIndexPCF, pageSizePCF])

    const fetchListData = async () => {
        setLoading(true);
        const params = {
            LoanId: loanId, SessionId: getSessionId(),
            Pagendex: pageIndexPCF, PageSize: pageSizePCF
        };
        const data = await GetOwnersListofPFSById(params);
        if (data.resultObject !== "") {
            setPfsAPplicationList(JSON.parse(data.resultObject));
            let total = data.message.split(" ");
            setTotalDataPCF(Number(total[1]));
        }
        setLoading(false);
    }

    const handleEditClick = (item) => {
        const encodedURL = `appLoanID=${loanId}&appOwnerID=${item.ID}&appNumber=${applicationNumber}`;
        navigate(`/pfs-detail?guest=false&data=${encryptText(encodedURL)}`);
    };

    const handleConfirmEmailAccess = async () => {
        setLoading(true);
        const encodedURL = `appLoanID=${loanId}&appOwnerID=${emailSentToItem.ID}&appNumber=${applicationNumber}`;

        const payload = {
            LoanId: loanId,
            sessionId: getSessionId(),
            Email: emailSentToItem.Email,
            OwnerId: emailSentToItem.ID,
            WebURL: `/pfs-detail?guest=true&data=${encryptText(encodedURL)}`
        };
        const data = await SendOwnerEmailonPersonalFinance(payload);
        setLoading(false);
        if (data.status == 200) {
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: data.message, type: "success" }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        } else {
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: data?.response?.data?.title, type: "error" }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        }
        setSentEmailPopup(false);
    }

    const pfsListTable = (item, index) => {
        return (
            <tr key={index}>
                <td>{item.Name} {item.LastName}</td>
                <td>{item.Title}</td>
                <td>{item.Email}</td>
                <td>{item.Ownership}</td>
                <td>
                    <Link onClick={() => {
                        setSentEmailPopup(true);
                        setEmailSentToItem(item)
                    }}
                        title="Send Email" href="#"><img className="lm-grid-edit-icon mr-2" src={email_icon} alt="" />
                    </Link>
                    <img
                        className="lm-grid-edit-icon"
                        src={edit_pen}
                        alt=""
                        onClick={() => handleEditClick(item)}
                        title="Edit"
                        style={{ cursor: 'pointer' }}  // Add cursor pointer style to indicate it's clickable
                    />
                </td>
            </tr>
        )
    }

    return (
        <>
            {/* SidePanel */}
            <SidePanel />

            {/* ProfileHeader */}
            <ProfileHeader />

            {loading ? (<Preloader />) : (<div>

                {/* <!-- owner form card --> */}
                <div className="lm-card p-0">
                    <div className="d-flex justify-content-between p-3">
                        <h3 className="lm-card-heading mb-0">Owners</h3>
                    </div>

                    <DynamicTable
                        data={pfsAPplicationList}
                        dynamicTableDisplay={pfsListTable}
                        columns={PFSApplicationList}
                        changePageSize={setPageSizePCF}
                        pageSize={pageSizePCF}
                        total={totalDataPCF}
                        setList={setInquiriesPCF}
                        setPageIndex={setPageIndexPCF}
                        pageIndex={pageIndexPCF}
                    />

                </div>
                {/* <!-- owner form card --> */}

                {/* <!--sent an email Popup / Modal --> */}
                <Modal
                    className='mark-comp-popup-cust-size'
                    show={sentEmailPopupShow}
                    onHide={() => setSentEmailPopup(false)}
                    aria-labelledby="sentEmailPopupLabel"
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-body signout-modal">
                                <h3 className="pt-4">
                                    Are you sure you want to sent an email?
                                </h3>
                            </div>
                            <div className="modal-footer la-app-modal-footer">
                                <button type="button" className="lm-button2" onClick={sentEmailPopupClose}>Cancel</button>
                                <button type="button" className="lm-button1" onClick={() => { handleConfirmEmailAccess() }}>Confirm</button>
                            </div>
                        </div>
                    </div>
                </Modal>
                {/* <!--sent an email Popup / Modal --> */}

            </div>)}

        </>
    );
}

export default PFS;