import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { InsertUpdateContractor, GetIndustryDetailsById } from '../../services/IndustriesServices'; // Import your service function

// Async thunk for fetching existing Contractor data
export const fetchContractorData = createAsyncThunk('contractorForm/fetchContractorData', async ({ loanId, type }) => {
  const response = await GetIndustryDetailsById(loanId, type);
  return response;
});

// Async thunk for form submission (save and update)
export const submitForm = createAsyncThunk('contractorForm/submitForm', async (formData) => {
    const response = await InsertUpdateContractor(formData);
    return response;
});

const contractorFormSlice = createSlice({
  name: 'contractorForm',
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {
    resetFormState: (state) => {
      state.data = null;
      state.loading = false;
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchContractorData.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(fetchContractorData.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    })
    .addCase(fetchContractorData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    })
      .addCase(submitForm.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(submitForm.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(submitForm.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});


export const { resetFormState } = contractorFormSlice.actions;
export default contractorFormSlice.reducer;
