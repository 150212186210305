import counter, { counterActions } from './counter';
import auth, { authActions } from './auth';
import toaster, { toasterActions } from './toasterSlice';
import errorbox, { errorActions } from './errorSlice';
import editProfile, { profileAction } from './editProfile';
import sessionReducer, { 
    showWarning, 
    hideWarning, 
    setLoading, 
    startSessionTimeout, 
    clearSessionTimeout, 
    checkSessionOnLoad, 
    handleConfirmSession 
} from './sessionSlice';

const reducersAndActions = {
    reducers: {
        counter,
        auth,
        toaster,
        errorbox,
        editProfile,
        session: sessionReducer, // Add session reducer here
    },
    actions: {
        counterActions,
        authActions,
        toasterActions,
        errorActions,
        profileAction,
        sessionActions: { // Add session actions here
            showWarning,
            hideWarning,
            setLoading,
            startSessionTimeout,
            clearSessionTimeout,
            checkSessionOnLoad,
            handleConfirmSession,
        }
    }
};

export default reducersAndActions;
