import { FormBuilder, Validators } from "react-reactive-form";

// Function Declerations
const borrowAmountValidator = (control) => {
    if (!control.value) {
        return null;
    } else if (control.value && Number(control.value.split(",").join("")) < 0) {
        return { borrowAmountIssue: true };
    } else if (control.value && Number(control.value.split(",").join("")) > 10000000000) {
        return { borrowAmountMaxIssue: true };
    }
    return null;
};

// PFS Checkings Starts
export const createPfsCheckingsDetailsList = (pfsDetail) => {
    const defaultValues = { currentBalance: "", financialInstitution: "", ownerId: "", pfsId: "" };
    const mergedValues = { ...defaultValues, ...pfsDetail };

    return FormBuilder.group({
        currentBalance: [mergedValues.currentBalance, [borrowAmountValidator, Validators.pattern("^[0-9,.]*$")]],
        financialInstitution: [mergedValues.financialInstitution],
        ownerId: [mergedValues.ownerId],
        pfsId: [mergedValues.pfsId],
    });
}

export const returnPfsFormGroupInArray = (pfsData, formGroup, keyName) => {
    let groupControl = formGroup.get(keyName);

    while (groupControl.length) {
        groupControl.removeAt(0);
    }

    pfsData.forEach((key) => {
        const newPfs = createPfsCheckingsDetailsList(key);
        groupControl.push(newPfs);
    });
}
// PFS Checkings Ends

// PFS CDs Starts
export const createCdsCheckingsDetailsList = (pfsDetail) => {
    const defaultValues = { currentValue: "", financialInstitution: "", maturityDate: "", ownerId: "", pfsId: "" };
    const mergedValues = { ...defaultValues, ...pfsDetail };

    return FormBuilder.group({
        currentValue: [mergedValues.currentValue, [borrowAmountValidator, Validators.pattern("^[0-9,.]*$")]],
        financialInstitution: [mergedValues.financialInstitution],
        maturityDate: [mergedValues.maturityDate],
        ownerId: [mergedValues.ownerId],
        pfsId: [mergedValues.pfsId],
    });
}

export const returnCdsFormGroupInArray = (pfsData, formGroup, keyName) => {
    let groupControl = formGroup.get(keyName);
    while (groupControl.length) {
        groupControl.removeAt(0);
    }
    pfsData.forEach((key) => {
        const newPfs = createCdsCheckingsDetailsList(key);
        groupControl.push(newPfs);
    });
}
// PFS CDs Ends

// PFS createOtherLiabilitiesDetailsList Starts

export const createOtherLiabilitiesDetailsList = (pfsDetail) => {
    const defaultValues = { marketValue: "", liabilityType: "", ownerId: 0, pfsId: 0 };
    const mergedValues = { ...defaultValues, ...pfsDetail };

    return FormBuilder.group({
        marketValue: [mergedValues.marketValue, [borrowAmountValidator, Validators.pattern("^[0-9,.]*$")]],
        liabilityType: [mergedValues.liabilityType],
        ownerId: [mergedValues.ownerId],
        pfsId: [mergedValues.pfsId],
    });
}

export const returnOtherLiabilitiesFormGroupInArray = (pfsData, formGroup, keyName) => {
    let groupControl = formGroup.get(keyName);
    while (groupControl.length) {
        groupControl.removeAt(0);
    }
    pfsData.forEach((key) => {
        const newPfs = createOtherLiabilitiesDetailsList(key);
        groupControl.push(newPfs);
    });
}

// PFS OtherLiabilitiesDetailsList Starts

// PFS PfsOtherLiabilitiesDetailList Starts
export const createOtherPersonalPropertyDetailsList = (pfsDetail) => {
    const defaultValues = { marketValue: "", typeOfOtherPersonalProperty: "", ownerId: 0, pfsId: 0 };
    const mergedValues = { ...defaultValues, ...pfsDetail };

    return FormBuilder.group({
        marketValue: [mergedValues.marketValue, [borrowAmountValidator, Validators.pattern("^[0-9,.]*$")]],
        typeOfOtherPersonalProperty: [mergedValues.typeOfOtherPersonalProperty],
        ownerId: [mergedValues.ownerId],
        pfsId: [mergedValues.pfsId],
    });
}

export const returnPfsOtherPersonalPropertyDetailList = (pfsData, formGroup, keyName) => {
    let groupControl = formGroup.get(keyName);
    while (groupControl.length) {
        groupControl.removeAt(0);
    }
    pfsData.forEach((key) => {
        const newPfs = createOtherPersonalPropertyDetailsList(key);
        groupControl.push(newPfs);
    });
}

// PFS PfsOtherLiabilitiesDetailList Ends

// PFS PfsContingentLiabilitiesDetailList Starts
export const createContingentLiabilitiesDetailsList = (pfsDetail) => {
    const defaultValues = { asEndorser: "", legalClaims: "", provisionForFederalIncomeTax: "", otherSpecialDebt: "", ownerId: 0, pfsId: 0 };
    const mergedValues = { ...defaultValues, ...pfsDetail };

    const data =  FormBuilder.group({
        asEndorser: [mergedValues.asEndorser],
        legalClaims: [mergedValues.legalClaims],
        provisionForFederalIncomeTax: [mergedValues.provisionForFederalIncomeTax],
        otherSpecialDebt: [mergedValues.otherSpecialDebt],
        ownerId: [mergedValues.ownerId],
        pfsId: [mergedValues.pfsId],
    });
    console.log(data.value)
    return data;
}

export const returnContingentLiabilitiesDetailsList = (pfsData, formGroup, keyName) => {
    let groupControl = formGroup.get(keyName);
    while (groupControl.length) {
        groupControl.removeAt(0);
    }
    pfsData.forEach((key) => {
        const newPfs = createContingentLiabilitiesDetailsList(key);
        groupControl.push(newPfs);
    });
}
// PFS PfsContingentLiabilitiesDetailList Ends

// pfs retrunPfsOtherAssetsDetailList Starts
export const createOtherAssetsDetailsList = (pfsDetail) => {
    const defaultValues = { marketValue: "", typeOfAsset: "", ownerId: 0, pfsId: 0 };
    const mergedValues = { ...defaultValues, ...pfsDetail };

    return FormBuilder.group({
        marketValue: [mergedValues.marketValue, [borrowAmountValidator, Validators.pattern("^[0-9,.]*$")]],
        typeOfAsset: [mergedValues.typeOfAsset],
        ownerId: [mergedValues.ownerId],
        pfsId: [mergedValues.pfsId],
    });
}

export const returnPfsOtherAssetsDetailList = (pfsData, formGroup, keyName) => {
    let groupControl = formGroup.get(keyName);
    while (groupControl.length) {
        groupControl.removeAt(0);
    }
    pfsData.forEach((key) => {
        const newPfs = createOtherAssetsDetailsList(key);
        groupControl.push(newPfs);
    });
}
// pfs retrunPfsOtherAssetsDetailList Ends


// PFS PfsOwnedRealEstateDetailsList Starts
let PfsRentedDetailListObject = {
    pfsId: "",
    ownerID: "",
    type: "",
    propertyType: "",
    address: "",
    purchaseDate: "",
    originalCost: "",
    presentMarketValue: "",
    monthlyNetRentalIncome: "",
    nameAddressOfMortgageHolder: "",
    mortgageAccountNumber: "",
    mortgageBalance: "",
    monthlyPayment: "",
    includesTaxAndInsurance: false,
    status: "",
    isAdditionalProperty: false,
    additionalRealEstateDetailsId: "",
}

export const createPfsOwnedRealEstateDetailsList = (pfsDetail = PfsRentedDetailListObject) => {
    return FormBuilder.group({
        pfsId: [pfsDetail.pfsId],
        ownerID: [pfsDetail.ownerID],
        propertyType: [pfsDetail.propertyType],
        address: [pfsDetail.address],
        purchaseDate: [pfsDetail.purchaseDate],
        originalCost: [pfsDetail.originalCost],
        presentMarketValue: [pfsDetail.presentMarketValue],
        monthlyNetRentalIncome: [pfsDetail.monthlyNetRentalIncome],
        nameAddressOfMortgageHolder: [pfsDetail.nameAddressOfMortgageHolder],
        mortgageAccountNumber: [pfsDetail.mortgageAccountNumber],
        mortgageBalance: [pfsDetail.mortgageBalance],
        monthlyPayment: [pfsDetail.monthlyPayment],
        includesTaxAndInsurance: [pfsDetail.includesTaxAndInsurance],
        status: [pfsDetail.status],
        isAdditionalProperty: [pfsDetail.isAdditionalProperty],
        additionalRealEstateDetailsId: [pfsDetail.additionalRealEstateDetailsId],
        streetAddress1: [pfsDetail.streetAddress1],
        streetAddress2: [pfsDetail.streetAddress2],
        city: [pfsDetail.city],
        state: [pfsDetail.state],
        zipcode: [pfsDetail.zipcode],
    });
}

export const returnPfsOwnedRealEstateDetailsListArray = (pfsData, formGroup, keyName) => {
    let groupControl = formGroup.get(keyName);
    while (groupControl.length) {
        groupControl.removeAt(0);
    }
    pfsData.forEach((key) => {
        const newPfs = createPfsOwnedRealEstateDetailsList(key);
        groupControl.push(newPfs);
    });
}

// PFS PfsOwnedRealEstateDetailsList Ends

// PFS PfsAutomobileDetailsList Starts
let pfsAutomobileDetailsListObject = {
    lender: "",
    make: "",
    marketValue: "",
    model: "",
    monthlyPayment: "",
    outstandingLoanBalance: "",
    ownerID: "",
    pfsId: "",
    status: "",
    year: "",
    vin: ""
}

export const createPfsAutomobileDetailsList = (pfsDetail = pfsAutomobileDetailsListObject) => {
    return FormBuilder.group({
        lender: [pfsDetail.lender],
        make: [pfsDetail.make],
        marketValue: [pfsDetail.marketValue],
        model: [pfsDetail.model],
        monthlyPayment: [pfsDetail.monthlyPayment],
        outstandingLoanBalance: [pfsDetail.outstandingLoanBalance],
        ownerID: [pfsDetail.ownerID],
        pfsId: [pfsDetail.pfsId],
        status: [pfsDetail.status],
        year: [pfsDetail.year],
        vin: [pfsDetail.vin ? pfsDetail.vin : ""],
    });
}

export const returnPfsAutomobileDetailsListArray = (pfsData, formGroup, keyName) => {
    let groupControl = formGroup.get(keyName);
    // Clearing the whole Array
    while (groupControl.length) {
        groupControl.removeAt(0);
    }

    // Filling up the data
    pfsData.forEach((key) => {
        const newPfs = createPfsAutomobileDetailsList(key);
        groupControl.push(newPfs);
    });
}

// PFS PfsAutomobileDetailsList Ends

// PFS PfsMarketableSecuritiesDetailsList Starts
let pfsMarketableSecuritiesDetailsListObject = {
    dateOfValue: "",
    exchangeListed: "",
    marketValue: "",
    name: "",
    numberOfShares: "",
    originalCost: "",
    ownerID: "",
    pfsId: "",
    pledged: "",
    totalValue: "",
}

export const createPfsMarketableSecuritiesDetailsList = (pfsDetail = pfsMarketableSecuritiesDetailsListObject) => {
    return FormBuilder.group({
        dateOfValue: [pfsDetail.dateOfValue],
        exchangeListed: [pfsDetail.exchangeListed],
        marketValue: [pfsDetail.marketValue],
        name: [pfsDetail.name],
        numberOfShares: [pfsDetail.numberOfShares],
        originalCost: [pfsDetail.originalCost],
        ownerID: [pfsDetail.ownerID],
        pfsId: [pfsDetail.pfsId],
        pledged: [pfsDetail.pledged],
        totalValue: [pfsDetail.totalValue],
    });
}

export const returnPfsMarketableSecuritiesDetailsListArray = (pfsData, formGroup, keyName) => {
    let groupControl = formGroup.get(keyName);
    // Clearing the whole Array
    while (groupControl.length) {
        groupControl.removeAt(0);
    }

    // Filling up the data
    pfsData.forEach((key) => {
        const newPfs = createPfsMarketableSecuritiesDetailsList(key);
        groupControl.push(newPfs);
    });
}

// PFS PfsMarketableSecuritiesDetailsList Ends

// PFS PfsAccountReceivablesDetailsList Starts
let pfsAccountReceivablesDetailsListObject = {
    address: "",
    currentBalance: "",
    frequency: "",
    maturityDate: "",
    nameOfNotePayer: "",
    originalBalance: "",
    ownerID: "",
    paymentAmount: "",
    pfsId: "",
    secured: "",
    status: "",
    streetAddress1: "",
    streetAddress2: "",
    city: "",
    state: "",
    zipcode: "",
}

export const createPfsAccountReceivablesDetailsList = (pfsDetail = pfsAccountReceivablesDetailsListObject) => {
    return FormBuilder.group({
        address: [pfsDetail.address],
        currentBalance: [pfsDetail.currentBalance],
        frequency: [pfsDetail.frequency],
        maturityDate: [pfsDetail.maturityDate],
        nameOfNotePayer: [pfsDetail.nameOfNotePayer],
        originalBalance: [pfsDetail.originalBalance],
        ownerID: [pfsDetail.ownerID],
        paymentAmount: [pfsDetail.paymentAmount],
        pfsId: [pfsDetail.pfsId],
        secured: [pfsDetail.secured],
        status: [pfsDetail.status],
        streetAddress1: [pfsDetail.streetAddress1],
        streetAddress2: [pfsDetail.streetAddress2],
        city: [pfsDetail.city],
        state: [pfsDetail.state],
        zipcode: [pfsDetail.zipcode],
    });
}

export const returnPfsAccountReceivablesDetailsListArray = (pfsData, formGroup, keyName) => {
    let groupControl = formGroup.get(keyName);
    // Clearing the whole Array
    while (groupControl.length) {
        groupControl.removeAt(0);
    }

    // Filling up the data
    pfsData.forEach((key) => {
        const newPfs = createPfsAccountReceivablesDetailsList(key);
        groupControl.push(newPfs);
    });
}

// PFS PfsMarketableSecuritiesDetailsList Ends

// PFS PfsLifeInsuranceDetailsList Starts
let pfsLifeInsuranceDetailsListObject = {
    amountBorrowed: "",
    beneficiaries: "",
    cashSurrenderValue: "",
    faceValue: "",
    insuranceCompany: "",
    ownerID: "",
    pfsId: "",
    pledged: "",
    state: "",
    purchaseDate: "",
}

export const createPfsLifeInsuranceDetailsList = (pfsDetail = pfsLifeInsuranceDetailsListObject) => {
    return FormBuilder.group({
        amountBorrowed: [pfsDetail.amountBorrowed],
        beneficiaries: [pfsDetail.beneficiaries],
        cashSurrenderValue: [pfsDetail.cashSurrenderValue],
        faceValue: [pfsDetail.faceValue],
        insuranceCompany: [pfsDetail.insuranceCompany],
        ownerID: [pfsDetail.ownerID],
        pfsId: [pfsDetail.pfsId],
        pledged: [pfsDetail.pledged],
        state: [pfsDetail.state],
        purchaseDate: [pfsDetail.purchaseDate],
    });
}

export const returnPfsLifeInsuranceDetailsListArray = (pfsData, formGroup, keyName) => {
    let groupControl = formGroup.get(keyName);
    // Clearing the whole Array
    while (groupControl.length) {
        groupControl.removeAt(0);
    }

    // Filling up the data
    pfsData.forEach((key) => {
        const newPfs = createPfsLifeInsuranceDetailsList(key);
        groupControl.push(newPfs);
    });
}

// PFS PfsLifeInsuranceDetailsList Ends


// PFS PfsRetirmentAccountsList Starts
let pfsRetirmentAccountsListObject = {
    aggregateMarketValue: "",
    descOfHoldings: "",
    ownerId: "",
    pfsId: ""
}

export const createPfsRetirementAccountsDetailsList = (pfsDetail = pfsRetirmentAccountsListObject) => {
    return FormBuilder.group({
        aggregateMarketValue: [pfsDetail.aggregateMarketValue],
        descOfHoldings: [pfsDetail.descOfHoldings],
        ownerID: [pfsDetail.ownerId],
        pfsId: [pfsDetail.pfsId],
    });
}

export const returnPfsRetirementAccountsDetailsListArray = (pfsData, formGroup, keyName) => {
    let groupControl = formGroup.get(keyName);
    // Clearing the whole Array
    while (groupControl.length) {
        groupControl.removeAt(0);
    }

    // Filling up the data
    pfsData.forEach((key) => {
        const newPfs = createPfsRetirementAccountsDetailsList(key);
        groupControl.push(newPfs);
    });
}

// PFS PfsRetirmentAccountsList Ends


// PFS PfsNotesPayableToBanksAndInstitutionsDetailsList Starts

let pfsNotesPayableToBanksAndInstitutionsDetailsListObject = {
    pfsId: "",
    ownerID: "",
    nameOfNoteHolder: "",
    address: "",
    originalBalance: "",
    currentBalance: "",
    paymentAmount: "",
    frequency: "",
    maturityDate: "",
    secured: "",
    typeofCollateral: ""
}

export const createPfsNotesPayableToBanksAndInstitutionsDetailsList = (pfsDetail = pfsNotesPayableToBanksAndInstitutionsDetailsListObject) => {
    return FormBuilder.group({
        pfsId: [pfsDetail.pfsId],
        ownerID: [pfsDetail.ownerID],
        nameOfNoteHolder: [pfsDetail.nameOfNoteHolder],
        address: [pfsDetail.address],
        originalBalance: [pfsDetail.originalBalance],
        currentBalance: [pfsDetail.currentBalance],
        paymentAmount: [pfsDetail.paymentAmount],
        frequency: [pfsDetail.frequency],
        maturityDate: [pfsDetail.maturityDate],
        secured: [pfsDetail.secured],
        typeofCollateral: [pfsDetail.typeofCollateral],
    });
}

export const returnPfsNotesPayableToBanksAndInstitutionsDetailsListArray = (pfsData, formGroup, keyName) => {
    let groupControl = formGroup.get(keyName);
    // Clearing the whole Array
    while (groupControl.length) {
        groupControl.removeAt(0);
    }

    // Filling up the data
    pfsData.forEach((key) => {
        const newPfs = createPfsNotesPayableToBanksAndInstitutionsDetailsList(key);
        groupControl.push(newPfs);
    });
}

// PFS PfsNotesPayableToBanksAndInstitutionsDetailsList Ends

// PFS pfsNotesPaybleToIndividualsDetailsList Starts

let pfsNotesPaybleToIndividualsDetailsListObject = {
    pfsId: "",
    ownerID: "",
    nameOfNoteHolder: "",
    address: "",
    originalBalance: "",
    currentBalance: "",
    paymentAmount: "",
    frequency: "",
    maturityDate: "",
    secured: "",
    typeOfCollateral: ""
}

export const createPfsNotesPayableToIndividualsDetailsList = (pfsDetail = pfsNotesPaybleToIndividualsDetailsListObject) => {
    return FormBuilder.group({
        pfsId: [pfsDetail.pfsId],
        ownerID: [pfsDetail.ownerID],
        nameOfNoteHolder: [pfsDetail.nameOfNoteHolder],
        address: [pfsDetail.address],
        originalBalance: [pfsDetail.originalBalance],
        currentBalance: [pfsDetail.currentBalance],
        paymentAmount: [pfsDetail.paymentAmount],
        frequency: [pfsDetail.frequency],
        maturityDate: [pfsDetail.maturityDate],
        secured: [pfsDetail.secured],
        typeOfCollateral: [pfsDetail.typeOfCollateral],
    });
}

export const returnPfsNotesPayableToIndividualsDetailsListArray = (pfsData, formGroup, keyName) => {
    let groupControl = formGroup.get(keyName);
    // Clearing the whole Array
    while (groupControl.length) {
        groupControl.removeAt(0);
    }

    // Filling up the data
    pfsData.forEach((key) => {
        const newPfs = createPfsNotesPayableToIndividualsDetailsList(key);
        groupControl.push(newPfs);
    });
}

// PFS pfsNotesPaybleToIndividualsDetailsList Ends

// PFS pfsInstallmentAccountDetailsList Starts

let pfsInstallmentAccountDetailsListObject = {
    pfsId: "",
    ownerID: "",
    installmentType: "",
    asset: "",
    marketValue: "",
    lender: "",
    outstandingLoanBalance: "",
    monthlyPayment: "",
    status: "",
}

export const createPfsInstallmentAccountDetailsList = (pfsDetail = pfsInstallmentAccountDetailsListObject) => {
    return FormBuilder.group({
        pfsId: [pfsDetail.pfsId],
        ownerID: [pfsDetail.ownerID],
        installmentType: [pfsDetail.installmentType],
        asset: [pfsDetail.asset],
        marketValue: [pfsDetail.marketValue],
        lender: [pfsDetail.lender],
        outstandingLoanBalance: [pfsDetail.outstandingLoanBalance],
        monthlyPayment: [pfsDetail.monthlyPayment],
        status: [pfsDetail.status],
    });
}

export const returnPfsInstallmentAccountDetailsListArray = (pfsData, formGroup, keyName) => {
    let groupControl = formGroup.get(keyName);
    // Clearing the whole Array
    while (groupControl.length) {
        groupControl.removeAt(0);
    }

    // Filling up the data
    pfsData.forEach((key) => {
        const newPfs = createPfsInstallmentAccountDetailsList(key);
        groupControl.push(newPfs);
    });
}

// PFS pfsInstallmentAccountDetailsList Ends

//PFS pfsUnpaidTaxesDetailsList Starts

let pfsUnpaidTaxesDetailsListObject = {
    pfsId: "",
    ownerID: "",
    typeOfTax: "",
    taxEntity: "",
    unpaidTaxBalance: "",
    dueDate: "",
    lien: "",
    propertyAddress: "",
    agency: "",
    lientType: "",
}

export const createPfsUnpaidTaxesDetailsList = (pfsDetail = pfsUnpaidTaxesDetailsListObject) => {
    return FormBuilder.group({
        pfsId: [pfsDetail.pfsId],
        ownerID: [pfsDetail.ownerID],
        typeOfTax: [pfsDetail.typeOfTax],
        taxEntity: [pfsDetail.taxEntity],
        unpaidTaxBalance: [pfsDetail.unpaidTaxBalance],
        dueDate: [pfsDetail.dueDate],
        lien: [pfsDetail.lien],
        propertyAddress: [pfsDetail.propertyAddress],
        agency: [pfsDetail.agency],
        lientType: [pfsDetail.lientType],
    });
}

export const returnPfsUnpaidTaxesDetailsListArray = (pfsData, formGroup, keyName) => {
    let groupControl = formGroup.get(keyName);
    // Clearing the whole Array
    while (groupControl.length) {
        groupControl.removeAt(0);
    }

    // Filling up the data
    pfsData.forEach((key) => {
        const newPfs = createPfsUnpaidTaxesDetailsList(key);
        groupControl.push(newPfs);
    });
}

// PFS pfsUnpaidTaxesDetailsList Ends

// PFS returnPfsAccountPayablesDetailListArray Starts

let pfsAccountPayablesDetailListObject = {
    pfsId: "",
    ownerID: "",
    nameOfNotePayer: "",
    originalBalance: "",
    currentBalance: "",
    paymentAmount: "",
    frequency: "",
    maturityDate: "",
    secured: "",
    status: "",
    streetAddress1: "",
    city: "",
    state: "",
    zipcode: "",
}

export const createPfsAccountPayablesDetailList = (pfsDetail = pfsAccountPayablesDetailListObject) => {
    return FormBuilder.group({
        pfsId: [pfsDetail.pfsId],
        ownerID: [pfsDetail.ownerID],
        nameOfNotePayer: [pfsDetail.nameOfNotePayer],
        originalBalance: [pfsDetail.originalBalance],
        currentBalance: [pfsDetail.currentBalance],
        paymentAmount: [pfsDetail.paymentAmount],
        frequency: [pfsDetail.frequency],
        maturityDate: [pfsDetail.maturityDate],
        secured: [pfsDetail.secured],
        status: [pfsDetail.status],
        streetAddress1: [pfsDetail.streetAddress1],
        city: [pfsDetail.city],
        state: [pfsDetail.state],
        zipcode: [pfsDetail.zipcode],
    });
}

export const returnPfsAccountPayablesDetailListArray = (pfsData, formGroup, keyName) => {
    let groupControl = formGroup.get(keyName);
    // Clearing the whole Array
    while (groupControl.length) {
        groupControl.removeAt(0);
    }

    // Filling up the data
    pfsData.forEach((key) => {
        const newPfs = createPfsAccountPayablesDetailList(key);
        groupControl.push(newPfs);
    });
}

// COMMON FUNCTIONS

// Add Checkings
export const addCheckings = (formGroup, keyName) => {
    const checkings = formGroup.get(keyName);
    const params = { ownerId: formGroup.value.ownerID, pfsId: formGroup.value.pfsid };

    let elementValidated = true;
    if (checkings.value.length > 0) {
        const lastElement = checkings.value[checkings.value.length - 1];
        elementValidated = Object.values(lastElement).every(value => value !== "" && value !== null);
    }

    if (elementValidated) {
        let newControl;
        switch (keyName) {
            case "pfsCheckingsDetailsList":
            case "pfsSavingsDetailsList":
                newControl = createPfsCheckingsDetailsList(params);
                break;
            case "pfscDsDetailsList":
                newControl = createCdsCheckingsDetailsList(params);
                break;
            case "pfsOtherLiabilitiesDetailList":
                newControl = createOtherLiabilitiesDetailsList(params);
                break;
            case "pfsRetirementAccountsDetailsList":
                newControl = createPfsRetirementAccountsDetailsList(params);
                break;
            case "pfsOtherPersonalPropertyDetailList":
                newControl = createOtherPersonalPropertyDetailsList(params);
                break;
            case "pfsContingentLiabilitiesDetailList":
                newControl = createContingentLiabilitiesDetailsList(params);
                break;
            case "pfsOtherAssetsDetailList":
                newControl = createOtherAssetsDetailsList(params);
                break;
            default:
                return; // Exit if keyName doesn't match any case
        }

        // Add validators to the new control
        Object.keys(newControl.controls).forEach(key => {
            newControl.get(key).setValidators([Validators.required]);
            if (key.toLowerCase().includes('balance') || key.toLowerCase().includes('value')) {
                newControl.get(key).setValidators([
                    Validators.required,
                    Validators.pattern("^[0-9,.]*$"),
                    borrowAmountValidator
                ]);
            }
        });

        checkings.push(newControl);

        // Mark the new control as touched and validate it
        newControl.markAsTouched();
        newControl.updateValueAndValidity();
    } else {
        // Show an error message or handle the case when the last element is not validated
        console.error("Please fill in all fields of the last entry before adding a new one.");
        // You might want to show a toaster message here
    }
};

// Function to remove a user from the form array
export const removeCheckings = (index, formGroup, keyName) => {
    const checkings = formGroup.get(keyName);
    let newData = checkings.controls.filter((ctrl, i) => i !== index);
    while (checkings.length) {
        checkings.removeAt(0);
    }

    setTimeout(() => {
        newData.forEach((key) => {
            checkings.push(key);
        });
    }, 10);
}

// Function to remove a user from the form array
export const removeAllCheckings = (formGroup, keyName) => {
    const checkings = formGroup.get(keyName);
    while (checkings && checkings.length) {
        checkings.removeAt(0);
    }
}