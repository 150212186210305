import { configureStore } from '@reduxjs/toolkit';
import slices from '@redux-local/slices';
import contractorFormReducer, { submitForm, resetFormState, fetchContractorData } from './slices/ContractorFormSlice';
import hotelFormReducer, { submitHotelForm, resetHotelFormState, fetchHotelData } from './slices/HotelFormSlice';
import restaurantFormReducer, { submitRestaurantForm, resetRestaurantFormState, fetchRestaurantData } from './slices/RestaurantFormSlice';
import medicalFormReducer, { submitMedicalForm, resetMedicalFormState, fetchMedicalData } from './slices/MedicalFormSice';
import gasStationFormReducer, { submitGasStationForm, resetGasStationFormState, fetchGasStationData } from './slices/GasStationFormSlice';
import templatedocumentFormReducer, { fetchTemplateDocumentList, submitTemplateDocument, fetchTemplateDocumentById, deleteTemplateDocument, resetTemplateDocumentState } from './slices/TemplateDocumentSlice';

const store = configureStore({
  reducer: {
    ...slices.reducers,
    contractorForm: contractorFormReducer,
    hotelForm: hotelFormReducer,
    restaurantForm: restaurantFormReducer,
    medicalForm: medicalFormReducer,
    gasStationForm: gasStationFormReducer,
    templateDocumentForm: templatedocumentFormReducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: false
  })
});


const actions = {
  ...slices.actions,
  contractorForm: {
    submitForm,
    resetFormState,
    fetchContractorData
  },
  hotelForm: {
    submitHotelForm,
    resetHotelFormState,
    fetchHotelData
  },
  restaurantForm: {
    submitRestaurantForm,
    resetRestaurantFormState,
    fetchRestaurantData
  },
  medicalForm: {
    submitMedicalForm,
    resetMedicalFormState,
    fetchMedicalData
  },
  gasStationForm: {
    submitGasStationForm,
    resetGasStationFormState,
    fetchGasStationData
  },
  templateDocumentForm: {
    fetchTemplateDocumentList,
    submitTemplateDocument,
    fetchTemplateDocumentById,
    deleteTemplateDocument,
    resetTemplateDocumentState
  },
};

export default store;
export { actions };
