import { useEffect } from "react";
import { GetHelloSignDownloadRequest } from "../services/documentsServices";

function UpdateHelloSignDocument() {
    useEffect(() => {
        getZipFileFromURL();
    }, []);

    function getCurrentUrlParams() {
        const params = {};
        const parsedUrl = new URL(window.location.href);

        // Iterate through all search parameters
        for (const [key, value] of parsedUrl.searchParams.entries()) {
            params[key] = value;
        }

        return params;
    }

    const getZipFileFromURL = async () => {

        const currentUrlParams = getCurrentUrlParams()
        await GetHelloSignDownloadRequest({ ...currentUrlParams });
        window.open('/loan-application', '_self');
    }

    const AccessRestricted = () => {
        return (
            <>
                <div className="">
                    <div className="not-found-page-body">
                        <div className="success-page-box not-found-box vh-100">
                            {/* <img src={not_found_404} /> */}
                            <h2>
                                Please wait a moment...
                                <br></br>
                                We are downloading your document.
                            </h2>
                            <p>
                                Do not close this window. You will be redirected to the documents page once the download is complete.
                                <br />
                                Please contact support if you believe this is an error.
                            </p>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return <AccessRestricted />;
}

export default UpdateHelloSignDocument;
