import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { InsertUpdateMedical ,GetIndustryDetailsById } from '../../services/IndustriesServices'; 


// Async thunk for fetching existing Contractor data
export const fetchMedicalData = createAsyncThunk('medicalForm/fetchMedicalData', async ({ loanId, type }) => {
    const response = await GetIndustryDetailsById(loanId, type);
    return response;
  });



// Async thunk for form submission (save and update)
export const submitMedicalForm = createAsyncThunk('medicalForm/submitMedicalForm', async (formData) => {
    const response = await InsertUpdateMedical(formData);
    return response;
});

const medicalSlice = createSlice({
    name: 'medicalForm',
    initialState: {
        data: null,
        loading: false,
        error: null,
    },
    reducers: {
        resetMedicalFormState: (state) => {
          state.data = null;
          state.loading = false;
          state.error = null;
        }
      },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMedicalData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchMedicalData.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchMedicalData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(submitMedicalForm.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(submitMedicalForm.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(submitMedicalForm.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const { resetMedicalFormState } = medicalSlice.actions;
export default medicalSlice.reducer;

