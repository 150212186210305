import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { InsertUpdateRestaurant ,GetIndustryDetailsById } from '../../services/IndustriesServices'; 


// Async thunk for fetching existing Contractor data
export const fetchRestaurantData = createAsyncThunk('restaurantForm/fetchRestaurantData', async ({ loanId, type }) => {
    const response = await GetIndustryDetailsById(loanId, type);
    return response;
  });



// Async thunk for form submission (save and update)
export const submitRestaurantForm = createAsyncThunk('restaurantForm/submitRestaurantForm', async (formData) => {
    const response = await InsertUpdateRestaurant(formData);
    return response;
});

const restaurantSlice = createSlice({
    name: 'restaurantForm',
    initialState: {
        data: null,
        loading: false,
        error: null,
    },
    reducers: {
        resetRestaurantFormState: (state) => {
          state.data = null;
          state.loading = false;
          state.error = null;
        }
      },
    extraReducers: (builder) => {
        builder
            .addCase(fetchRestaurantData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchRestaurantData.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchRestaurantData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(submitRestaurantForm.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(submitRestaurantForm.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(submitRestaurantForm.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const { resetRestaurantFormState } = restaurantSlice.actions;
export default restaurantSlice.reducer;

