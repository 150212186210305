import axios from '../helpers/axiosConfig';
import { getSessionId } from './storageData';
import { getLocalStorageData } from './storageData';


// Insert  Hotel details
export const InsertUpdateTemplateDocument = async (formdata) => {
    try {
        const response = await axios.post('/api/TemplateDocument/InsertUpdateTemplateDocument', formdata,
            {
                params: { SessionId: getSessionId() },
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${getLocalStorageData("token")}`
                }
            }
        );
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
};

export const GetTemplateDocumentDetailsById = async (loanId) => {
    try {

        const instance = axios.create();

        const response = await instance.get('/api/TemplateDocument/GetTemplateDocumentDetailsById', {
            params: {
                Id: loanId,
                SessionId: getSessionId()
            },
            headers: {
                'Authorization': `Bearer ${getLocalStorageData("token")}`
            }
        });

        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const GetTemplateDocumentList = async (pageIndex, pageSize, status, searchText) => {
    try {
        const response = await axios.get('/api/TemplateDocument/GetTemplateDocumentList', {
            params: {
                PageIndex: pageIndex,
                PageSize: pageSize,
                Status: status,
                SearchText: searchText,
                SessionId: getSessionId()
            },
            headers: {
                'Authorization': `Bearer ${getLocalStorageData("token")}`
            }
        });

        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const DeleteTemplateDocument = async (loanId) => {
    try {
        const response = await axios.get('/api/TemplateDocument/DeleteTemplateDocument',{
            params: {
                Id: loanId,
                SessionId: getSessionId(),
                ModifiedBy: JSON.parse(localStorage.getItem('user'))?.id || ""
            },
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getLocalStorageData("token")}`
            }
        });

        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.error(err);
        throw err;
    }
};