import moment from "moment";

export default function convertToLocalTime(dateTimeString) {
  
  const date = new Date(dateTimeString);
  

  // Check if the date is valid
  if (isNaN(date.getTime()) || dateTimeString === "0001-01-01T00:00:00") {
    return "NA";
  }

  const utcDate = moment.utc(dateTimeString);
  if (!utcDate.isValid()) {
    throw new Error('Invalid date and time string');
  }

  const localTimeString = utcDate.local().format('MMM DD YYYY, hh:mm:ss A');
  return localTimeString;
}


