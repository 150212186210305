import axios from '../helpers/axiosConfig';
import { getLocalStorageData, getSessionId } from './storageData';


export function convertToFormData(data) {
    const formData = new FormData();
    for (const element of Object.keys(data)) {
        const key = element;
        formData.append(key, data[key]);
    }
    return formData;
}
// Get Loan application listing
export const getLoanApplicationList = async (data) => {
    try {
        const response = await axios.get(`/api/LoanApplication/GetLoanApplicationList`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}

// Get basic info of loan application
export const GetBasicInfoLoanApplicationById = async (data) => {
    try {
        const response = await axios.get(`/api/LoanApplication/GetBasicInfoLoanApplication`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const InsertUpdateBasicInfo = async (data) => {
    try {
        const response = await axios.post(`/api/LoanApplication/InsertUpdateBasicInfo`, convertToFormData(data), { params: { SessionId: getSessionId() } });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}
export const InsertUpdateBasicInfoMailSend = async (data) => {
    try {
        const response = await axios.post(`/api/LoanApplication/UpdateBorrowerEmailAddressLink?LoanId=${data.LoanId}&BorrowerEmail=${data.BorrowerEmail}`);
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const UpdateBorrowerEmailAddress = async (data) => {
    try {
        const response = await axios.post(`/api/LoanApplication/UpdateBorrowerEmailAddress?LoanId=${data.LoanId}&BorrowerEmail=${data.BorrowerEmail}&SessionId=${data.SessionId}`);
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const ConfirmEmailInfo = async (data) => {
    try {
        const response = await axios.post(`/api/User/ActiveInactiveUserById`, { params: data.params });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}

// Get Loan Application Advisor, Lender and Partner listing
export const getDropdownAdvisorPartnerLenderList = async (data) => {
    try {
        // Data - Type, SessionId
        const response = await axios.get(`/api/LoanApplication/GetAdvisorPartnerLenderList`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}

// Get Loan Application Advisor, Lender and Partner by LoanId listing
export const GetAdvisorPartnerLenderDetailsByLoanId = async (data) => {
    try {
        // Data - Type, LoanId, SessionId
        const response = await axios.get(`/api/LoanApplication/GetAdvisorPartnerLenderDetailsByLoanId`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}

// Assign Loan Application Advisor, Lender and Partner by LoanId
export const AssignedAdvisorPartnerLenderToLoan = async (data) => {
    try {
        // Data - Type, LoanId, SessionId
        const response = await axios.post(`/api/LoanApplication/AssignedAdvisorPartnerLenderToLoan`, {}, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const GetAffiliateListByLoanId = async (data) => {
    try {
        const response = await axios.get(`/api/LoanApplication/GetAffiliateListByLoanId`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const GetAffiliateDetailsById = async (data) => {
    try {
        const response = await axios.get(`/api/LoanApplication/GetAffiliateDetailsById`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const InsertUpdateAffiliateInfo = async (data) => {
    try {
        const response = await axios.post(`/api/LoanApplication/InsertUpdateAffiliateInfo`, convertToFormData(data), { params: { SessionId: getSessionId() } });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const ActiveInactiveAffiliateById = async (data) => {
    try {
        const response = await axios.post(`/api/LoanApplication/ActiveInactiveAffiliateById`, {}, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const DeleteAffiliateById = async (data) => {
    try {
        const response = await axios.post(`/api/LoanApplication/DeleteAffiliateById`, {}, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}

// Get Business History Details
export const GetBussinessNameListByLoanId = async (data) => {
    try {
        // Data - Type, SessionId
        const response = await axios.get(`/api/LoanApplication/GetBusinessListById`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const GetBusinessHistoryDetailsById = async (data) => {
    try {
        // Data - Type, SessionId
        const response = await axios.get(`/api/LoanApplication/GetBusinessHistoryById`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}

// post Business History
export const InsertUpdateBusinessHistory = async (data) => {
    try {
        // Data - Type, SessionId
        const response = await axios.post(`api/LoanApplication/InsertUpdateBusinessHistory`, convertToFormData(data), { params: { SessionId: getSessionId() } });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}

// Get Loan Amount details
export const GetLoanAmountDetail = async (data) => {
    try {
        // Data - Type, SessionId
        const response = await axios.get(`/api/LoanApplication/GetLoanAmount`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}

// post Loan Amount details
export const InsertUpdateLoanAmount = async (data) => {
    try {
        // Data - Type, SessionId
        const response = await axios.post(`/api/LoanApplication/InsertOrUpdateLoanAmount`, convertToFormData(data), { params: { SessionId: getSessionId() } });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}


// Get Amortization details
export const GetAmortizationDetail = async (data) => {
    try {
        // Data - Type, SessionId
        const response = await axios.get(`/api/LoanApplication/GetAmortization`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}

// post Amortization details
export const InsertUpdateAmortization = async (data) => {
    try {
        // Data - Type, SessionId
        const response = await axios.post(`/api/LoanApplication/InsertOrUpdateAmortization`, convertToFormData(data), { params: { SessionId: getSessionId() } });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}


// PFS Section Starts Below ==========================

export const GetOwnersListofPFSById = async (data) => {
    try {
        const response = await axios.get(`/api/LoanApplication/GetOwnersListofPFSById`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}
export const GetPFSListByLoanId = async (data, guest = false) => {
    try {
        const URL = guest ? `Guest_GetPersonalFinanceStatementById` : `GetPersonalFinanceStatementById`;
        const response = await axios.get(`/api/LoanApplication/${URL}`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const insertUpdatePersonalFinance = async (data, guest = false) => {
    try {
        data['modifiedBy'] = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user"))?.id : 0;
        const URL = guest ? `Guest_InsertUpdatePersonalFinance` : `InsertUpdatePersonalFinance`;
        const response = await axios.post(`/api/LoanApplication/${URL}`, data,
            {
                params: { SessionId: getSessionId() },
                headers: {
                    'Content-Type': 'application/json', // Overwrite Content-Type here
                    'Authorization': `Bearer ${getLocalStorageData("token")}`
                }
            }
        );
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        return err;
    }
}

// PFS Section Ends Below ============================


// Send Owner Email on PersonalFinance start

export const SendOwnerEmailonPersonalFinance = async (data) => {
    try {
        const response = await axios.get(`/api/LoanApplication/SendOwnerEmailonPersonalFinance?${new URLSearchParams(data).toString()}`);
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        return err;
    }
}

// Send Owner Email on PersonalFinance end

//census API for Verify the NAICSCode
export const censusNAICSVerify = async (data) => {
    try {
        const apiUrl = `https://api.census.gov/data/2017/ecnbasic?get=NAICS2017_LABEL,EMP,NAME,GEO_ID&for=us:*&NAICS2017=${data.NAICSCode}&key=dcbbd5428f3566f8cdefb12a9ceb931dfdfd7d06`;
        const response = await fetch(apiUrl);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        return result;
    } catch (err) {
        console.error("Error:", err);
        throw err;
    }
};

export const InsertOrUpdateNewLoanExistingUser = async (data) => {
    try {
        const response = await axios.post(`/api/LoanApplication/InsertOrUpdateNewLoanExistingUser`, convertToFormData(data), { 
            params: { SessionId: getSessionId() },
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${getLocalStorageData("token")}`
            }
         });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        return err;
    }
}


export const GetVerification = async (data) => {
    try {
        const response = await axios.get(`/api/LoanApplication/GetVerification`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}