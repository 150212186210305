import React, { useState, useEffect } from 'react';
import { SidePanel, ProfileHeader } from "@components";
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import Preloader from '../plugins/Preloader';
import Info_tooltip from '../assets/icons/Info-tooltip.svg';
import ScroeCard from '../assets/img/ScroeCard.png';
import ScroeCardItems from '../assets/img/ScroeCardItems.png';
import PieChart from '../assets/img/pie-chart.png';
import { useDispatch } from 'react-redux';
import reducersAndActions from '../_redux/slices/index';

function DashboardBorrower() {
    const dispatch = useDispatch();
    useEffect(() => {
        document.title = "Dashboard";
    }, []);

    // Preloader Start
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(reducersAndActions.actions.sessionActions.setLoading(loading));
    }, [loading]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoading(false);
        }, 500);
        return () => clearTimeout(timeout);
    }, []);



    // Preloader End

    // datepicker function
    const [startDate, setStartDate] = useState(new Date());

    return (
        <>
            {/* SidePanel */}
            <SidePanel />

            {/* ProfileHeader */}
            <ProfileHeader />

            {loading ? (<Preloader />) : (<div>

                <div className="dashboard-wrap dashboard-screen">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="card-row">

                                <div className="db-card db-sm-card">
                                    <span className="db-sm-card-title">Date of next payment</span>
                                    <h3 className="db-sm-card-value">March 20, 2024</h3>
                                </div>

                                <div className="db-card db-sm-card">
                                    <span className="db-sm-card-title">Total payment term</span>
                                    <h3 className="db-sm-card-value">10 years </h3>
                                </div>

                                <div className="db-card db-sm-card">
                                    <span className="db-sm-card-title">total original amount
                                        <span className="lm-tooltip ml-1" data-tooltip="Total borrowed" data-tooltip-pos="up" data-tooltip-length="fit"> <img src={Info_tooltip} alt="" /> </span>
                                    </span>
                                    <h3 className="db-sm-card-value">$700,000.00</h3>
                                </div>

                                <div className="db-card db-sm-card">
                                    <span className="db-sm-card-title">Number of loans</span>
                                    <h3 className="db-sm-card-value">7</h3>
                                </div>

                                <div className="db-card db-sm-card">
                                    <span className="db-sm-card-title">total monthly payment</span>
                                    <h3 className="db-sm-card-value">$18,000.00</h3>
                                </div>

                                <div className="db-card db-sm-card">
                                    <span className="db-sm-card-title">outstanding balance
                                        <span className="lm-tooltip ml-1" data-tooltip="Left to be paid" data-tooltip-pos="up" data-tooltip-length="fit"> <img src={Info_tooltip} alt="" /> </span>
                                    </span>
                                    <h3 className="db-sm-card-value">$465,356.10</h3>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="db-card">
                                <h4 className="db-card-title">Type of loans</h4>
                                <div className="db-pie-chart">
                                    <img className="mt-1" src={PieChart} alt="" />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="grid-head d-flex justify-content-between">
                                        <h3>Last payments</h3>
                                    </div>
                                    <table className="lm-grid-table db-table">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <div className="d-flex">
                                                        payment name
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className="d-flex">
                                                        Status
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className="text-right">
                                                        amount
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><b><Link className="application_no">11990953-008977</Link> loan payment</b></td>
                                                <td> <div className="status new">Paid</div></td>
                                                <td className="text-right"><b>$10,100.40</b></td>
                                            </tr>
                                            <tr>
                                                <td><b><Link className="application_no">11990953-008977</Link> loan payment</b></td>
                                                <td> <div className="status closed">Unpaid</div></td>
                                                <td className="text-right"><b>$10,100.40</b></td>
                                            </tr>
                                            <tr>
                                                <td><b><Link className="application_no">11990953-008977</Link> loan payment</b></td>
                                                <td> <div className="status new">Paid</div></td>
                                                <td className="text-right"><b>$10,100.40</b></td>
                                            </tr>
                                            <tr>
                                                <td><b><Link className="application_no">11990953-008977</Link> loan payment</b></td>
                                                <td> <div className="status new">Paid</div></td>
                                                <td className="text-right"><b>$10,100.40</b></td>
                                            </tr>
                                            <tr>
                                                <td><b><Link className="application_no">11990953-008977</Link> loan payment</b></td>
                                                <td> <div className="status new">Paid</div></td>
                                                <td className="text-right"><b>$10,100.40</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-md-5">
                                    <div className="db-card">
                                        <h4 className="db-card-title">Average FICO score</h4>
                                        {/* ScroeCard */}
                                        <div className="ScroeCard mb-2">
                                            <img src={ScroeCard} alt="" />
                                        </div>
                                        <img className="mt-1" src={ScroeCardItems} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="db-card db-custom-calender">
                                <h4 className="db-card-title py-3 px-4">Payment Calendar</h4>
                                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} inline />
                            </div>
                        </div>

                        {/* Amortization schedule */}
                        <div className="col-12 amortization-table">
                            <div className="grid-head d-flex justify-content-between">
                                <h3>Amortization schedule</h3>
                            </div>
                            <table className="lm-grid-table" id="myTable">
                                <thead>
                                    <tr>
                                        <th>
                                            <div className="d-flex">
                                                №
                                                <span className="sort-arrow">
                                                    <div className="lm-angle-up-icon"></div>
                                                    <div className="lm-angle-down-icon"></div>
                                                </span>
                                            </div>
                                        </th>
                                        <th>
                                            <div className="d-flex">
                                                date of Payment
                                                <span className="sort-arrow">
                                                    <div className="lm-angle-up-icon"></div>
                                                    <div className="lm-angle-down-icon"></div>
                                                </span>
                                            </div>
                                        </th>
                                        <th>
                                            <div className="d-flex ml-auto w-max-content">
                                                Principal Payment
                                                <span className="sort-arrow">
                                                    <div className="lm-angle-up-icon"></div>
                                                    <div className="lm-angle-down-icon"></div>
                                                </span>
                                            </div>
                                        </th>
                                        <th>
                                            <div className="d-flex ml-auto w-max-content">
                                                Interest Payment
                                                <span className="sort-arrow">
                                                    <div className="lm-angle-up-icon"></div>
                                                    <div className="lm-angle-down-icon"></div>
                                                </span>
                                            </div>
                                        </th>
                                        <th>
                                            <div className="d-flex ml-auto w-max-content">
                                                Total Payment
                                                <span className="sort-arrow">
                                                    <div className="lm-angle-up-icon"></div>
                                                    <div className="lm-angle-down-icon"></div>
                                                </span>
                                            </div>
                                        </th>
                                        <th>
                                            <div className="d-flex ml-auto w-max-content">
                                                Ending Balance
                                                <span className="sort-arrow">
                                                    <div className="lm-angle-up-icon"></div>
                                                    <div className="lm-angle-down-icon"></div>
                                                </span>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><b>1</b></td>
                                        <td>Jan 23, 2024</td>
                                        <td className="text-right">$4,100.00</td>
                                        <td className="text-right">$910.50</td>
                                        <td className="text-right">$5010,50</td>
                                        <td className="text-right">$5010,50</td>
                                    </tr>
                                    <tr>
                                        <td><b>1</b></td>
                                        <td>Jan 23, 2024</td>
                                        <td className="text-right">$4,100.00</td>
                                        <td className="text-right">$910.50</td>
                                        <td className="text-right">$5010,50</td>
                                        <td className="text-right">$5010,50</td>
                                    </tr>
                                    <tr>
                                        <td><b>1</b></td>
                                        <td>Jan 23, 2024</td>
                                        <td className="text-right">$4,100.00</td>
                                        <td className="text-right">$910.50</td>
                                        <td className="text-right">$5010,50</td>
                                        <td className="text-right">$5010,50</td>
                                    </tr>
                                    <tr>
                                        <td><b>1</b></td>
                                        <td>Jan 23, 2024</td>
                                        <td className="text-right">$4,100.00</td>
                                        <td className="text-right">$910.50</td>
                                        <td className="text-right">$5010,50</td>
                                        <td className="text-right">$5010,50</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>

            </div>)}



        </>
    );
}

export default DashboardBorrower;