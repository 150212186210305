import React from 'react';
import ai_icon from '../../assets/img/ai_icon.svg';
import { getAIRePhaseContent } from '../../services/utilServices';

const AIRewriteButton = ({ fieldName, isFormGroup = true, formGroup, type = "", value = "", setHardData }) => {
    const finalValue = isFormGroup ? formGroup.value[fieldName] : value;

    const formateResponse = (content) => {
        try {
            return content.replace(/[\r\t\n]/g, '').replace(/[^a-zA-Z0-9., ]/g, '');
        } catch (error) {
            console.error("Error:", error.message);
            return content;
        }
    }

    const handleAIWrite = async () => {
        document.getElementById(`lm-ai-btn-${fieldName}`).classList.add('is_generating');
        const content = await getAIRePhaseContent(finalValue);
        const formattedContent = formateResponse(content);

        if (isFormGroup) {
            formGroup.patchValue({ [fieldName]: formattedContent })
        } else {
            setHardData(formattedContent);
        }
        isFormGroup ? formGroup.patchValue({ [fieldName]: formattedContent }) : setHardData(formattedContent);
        document.getElementById(`lm-ai-btn-${fieldName}`).classList.remove('is_generating');
    }

    return (
        <button
            type="button"
            className={type === 'textarea' ? "lm-ai-btn-bottom" : "lm-ai-btn"}
            id={`lm-ai-btn-${fieldName}`}
            onClick={() => handleAIWrite(fieldName)}
            style={{ backgroundColor: finalValue ? '' : '#7483b1' }}
            disabled={!finalValue}
        >
            <img src={ai_icon} alt="AI Icon" />
            AI rewrite
        </button>
    );
};

export default AIRewriteButton;
