import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import axiosInstance from '../../../helpers/axiosConfig';
import pass_eye from '../../../assets/img/pass_eye.svg';
import close_popup from '../../../assets/icons/close_popup.svg';
import reducersAndActions from '../../../_redux/slices';
import Constants from '../../../helpers/Constants';
import Preloader from '../../../plugins/Preloader';
import { type } from '@testing-library/user-event/dist/type';

const SelectAdminModal = ({ adminPopup, setAdminPopup, setIsConfirm = "" }) => {

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(reducersAndActions.actions.sessionActions.setLoading(loading));
  }, [loading]);



  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState([]);
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false)
  const togglePassword = () => {

    setShowPassword(!showPassword)
    const passField = document.getElementById('password');
    passField.type = passField.type === 'password' ? 'text' : 'password';

  };
  // Defining Functions --------------------------------


  const changePassPopupClose = () => {
    setAdminPopup(false);
  };

  const handleSave = () => {
    const storedUserId = localStorage?.getItem('user');
    const parsedUserId = JSON.parse(storedUserId);
    const storedSessionId = localStorage?.getItem('sessionId');
    if (!email || !password) {
      setErrors(['Email and password are required']);
      return;
    }
    setLoading(true)
    const apiUrl = `/api/User/UserLoginAuthentication`;

    axiosInstance.get(apiUrl, {
      params: {
        UserId: parsedUserId.id,
        SessionId: storedSessionId,
        EmailAddress: email,
        Password: password,
        SessionId: storedSessionId
      },
    })
      .then(response => {
        setLoading(false)
        if (response.data.status == '200') {
          dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.data.message, type: "success" }));
          setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
          if (setIsConfirm !== "") {
            setIsConfirm(true)
          }
          setAdminPopup(false);
        } else {
          dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.data.message, type: 'error' }));
          setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        }
      })
      .catch(error => {
        setLoading(false)
        console.error('Error:', error);
        setErrors(['Authentication failed, Please try again with valid credential.']);
      });
  };

  return (
    <Modal
      className='signout-modal '
      show={adminPopup}
      onHide={() => setAdminPopup(false)}
      aria-labelledby="changePassPopupLabel"
      backdrop="static"
      keyboard={false}
      centered
    >
      {loading ? <Preloader /> : <div>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="ChangePasswordPopupLabel">
              Log In to  Confirm Authentication
            </h5>
            <button
              type="button"
              className="close"
              onClick={changePassPopupClose}
            >
              <span aria-hidden="true">
                <img src={close_popup} alt="" />
              </span>
            </button>
          </div>
          <form>
            <Modal.Body>
              <div className="form-group input-password">
                <label htmlFor="email">Email Address</label>
                <input
                  type="email"
                  className="form-control"
                  id="email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email Address"
                />
              </div>
              <div className="form-group input-password">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  className="form-control password-input mb-1"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your password"
                />
                <img
                  onClick={togglePassword}
                  id="pass_eye"
                  className={`pass_eye ${showPassword ? 'active_pass' : ''}`}

                  src={pass_eye}
                  alt=""
                />
              </div>
              {errors.length > 0 && (
                <div className="alert alert-danger" role="alert">
                  {errors.join('. ')}
                </div>
              )}
            </Modal.Body>
            <div className="modal-footer">
              {/* <button
                type="button"
                className="lm-button2"
                onClick={changePassPopupClose}
              >
                Cancel
              </button> */}
              <button type="button" className="lm-button1 w-100" onClick={handleSave}>
                Log in
              </button>
            </div>
          </form>
        </div>
      </div>}
    </Modal>
  );
};

export default SelectAdminModal;
