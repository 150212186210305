import { createSlice } from '@reduxjs/toolkit';
import { getLocalStorageData } from '../../services/storageData';

const user = JSON.parse(getLocalStorageData("user"));

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLoggedIn: user ? true : false,
    user: user || {},
    sidePanel: user ? user.menulist : [],
  },
  reducers: {
    saveUser: (state, user) => {
      state.user = user.payload;
      state.isLoggedIn = true;
      state.sidePanel = user.payload.menulist;
    },
    removeUser: (state) => {
      state.user = {};
      state.isLoggedIn = false;
      state.sidePanel = [];
    }
  }
});

export const authActions = authSlice.actions;
export default authSlice.reducer;