export function getSessionId() {
    return localStorage.getItem('sessionId');
}

export function setSessionId(id) {
    return localStorage.setItem('sessionId', id);
}

export function setLocalStorageData(key, data) {
    return localStorage.setItem(key, data);
}

export function getLocalStorageData(key) {
    return localStorage.getItem(key);
}

export function removeLocalStorageData(key) {
    return localStorage.removeItem(key);
}

export function autoSetSessionId(id) {
    if (!getSessionId()) {
        setSessionId(id);
    }
}

export function flushLocalStorageData() {
    let arr = ["email", "token", "user", "avatarImage", "profileData", "sessionId", "sessionActive", "_grecaptcha", "sessionStartTime", "refreshToken", "sessionId"];
    for (let key of arr) {
        localStorage.removeItem(key);
    }
    return;
}