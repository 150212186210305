import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal'
import Select from 'react-select';
import { SidePanel, ProfileHeader } from "@components";
import Preloader from '../plugins/Preloader';
import { actions } from '../_redux/store';
import Constants, { commonDropdownOptions } from '../helpers/Constants';
import trash_icon_grid from '../assets/icons/trash-icon-grid.svg';
import edit_pen from '../assets/icons/edit-pen.svg';
import close_popup from '../assets/icons/close_popup.svg';
import pdf_file_icon from '../assets/img/pdf-file-icon.svg';
import { FieldGroup, FormBuilder, Validators, FieldControl } from "react-reactive-form";
import DynamicTable from '../plugins/DynamicTable';
import { templateDocumentColumns } from '../helpers/DropdownsAndMappers';
import { debounce } from 'lodash';
import { DeleteTemplateDocument, GetTemplateDocumentDetailsById, InsertUpdateTemplateDocument } from '../services/templateservices';
import { getMasterList } from '../services/utilServices';
import { GetUserList } from '../services/logsServices';
import { getSessionId } from '../services/storageData';
import reducersAndActions from '../_redux/slices';

const TemplateDocumentComponent = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(reducersAndActions.actions.sessionActions.setLoading(loading));
    }, [loading]);


    const [typeOfBusnessOptions, setTypeOfBusnessOptions] = useState([]);
    const [businessCategoriesOptions, setBusinessCategoriesOptions] = useState([]);
    const [lendersList, setLendersList] = useState([]);
    const [statusOptionsList, setStatusOptionsList] = useState('');

    const getTypesOfBusiness = async () => {
        const data = await getMasterList("TypeOfBusness");
        if (data && data.status == 200) {
            let responseData1 = JSON.parse(data.resultObject);
            const desiredOrder = ["Sole Proprietor", "Limited Liability Company (LLC)", "C-Corporation", "Partnership", "Independent Contractor", "S-Corporation"];
            responseData1.sort((a, b) => {
                return desiredOrder.indexOf(a.label) - desiredOrder.indexOf(b.label);
            });
            setTypeOfBusnessOptions(responseData1);
            setAddNewTemplateData((prevState) => ({ ...prevState, BusinessType: responseData1[0].value }));
        }
    }

    const getBusinessCategory = async () => {
        let data = await getMasterList("BusinessCategories");
        if (data && data.status == 200) {
            let responseData2 = JSON.parse(data.resultObject);
            const desiredOrder = ["Contractor", "Gas Station", "Hotel", "Medical", "Restaurant", "Transportation", "Others"];
            responseData2.sort((a, b) => {
                return desiredOrder.indexOf(a.label) - desiredOrder.indexOf(b.label);
            });
            setBusinessCategoriesOptions(responseData2);
            setStatusOptionsList([{ value: "", label: "All Categories" }, ...responseData2]);
            setAddNewTemplateData((prevState) => ({ ...prevState, businessCatagory: responseData2[0].value }));
        }
    }

    const getLendersList = async () => {
        const params = {
            PageIndex: 1, PageSize: 10000,
            roleName: "Lender", SessionId: getSessionId()
        }
        const data = await GetUserList(params);
        if (data && data.status == 200) {
            const finalList = data.resultObject
                .filter(user => user.statusDesc === "Active")
                .map(user => {
                    return {
                        value: user.id,
                        label: user.companyName
                    }
                })
            setLendersList(finalList);
            setAddNewTemplateData((prevState) => ({ ...prevState, existingLender: finalList[0].value }));
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await Promise.all([getTypesOfBusiness(), getBusinessCategory(), getLendersList()]);
        };
        fetchData();
    }, []);

    const [documentPopupShow, setDocumentPopup] = useState(false);
    const [editDocumentPopupShow, setEditDocumentPopup] = useState(false);
    const [confirmDeletePopupShow, setConfirmDeletePopup] = useState(false);

    const [documentTemplate, setDocumentTemplate] = useState([]);
    const [selectedDocumentId, setSelectedDocumentId] = useState(0);

    const [totalData, setTotalData] = useState(0);
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [inquiries, setInquiries] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [status, setStatus] = useState('');

    const [file, setFile] = useState(null);
    const [editTemplateData, setEditTemplateData] = useState({});
    const [addNewTemplateData, setAddNewTemplateData] = useState({ id: 0, documentName: '', existingLender: '', docuSign: false, allCases: false, chkBusiness: false, chkOwner: false, tempDocuFile: '', docType: 'UserUpload', BusinessType: '', ownerDocs: '', businessDocs: '', businessCatagory: '', postedFile: '' })

    const handleInputChange = (e) => {
        const { id, value, type, checked } = e.target;
        setAddNewTemplateData((prevState) => ({
            ...prevState,
            [id]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSelectChange = (selectedOption, actionMeta) => {
        const { name } = actionMeta;
        setAddNewTemplateData((prevState) => ({
            ...prevState,
            [name]: selectedOption.value
        }));
    };

    const handleEditSelectChange = (selectedOption, actionMeta) => {
        const { name } = actionMeta;
        setEditTemplateData((prevState) => ({
            ...prevState,
            [name]: selectedOption.value
        }));
    };

    const handleEditInputChange = (e) => {
        const { id, value, type, checked } = e.target;
        setEditTemplateData((prevState) => ({
            ...prevState,
            [id]: type === 'checkbox' ? checked : value
        }));
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    };

    let { data, list, error } = useSelector((state) => state.templateDocumentForm);

    // Fetch inquiries whenever page size or page index changes
    const debouncedGetInquiries = useCallback(debounce(() => {
        setLoading(true);
        dispatch(actions.templateDocumentForm.fetchTemplateDocumentList({
            pageIndex: pageIndex,
            pageSize: pageSize,
            status: status,
            searchText
        })).finally(() => {
            setLoading(false);
        });
    }, 1000), [pageIndex, pageSize, searchText, status]);


    useEffect(() => {
        debouncedGetInquiries();
        return () => {
            debouncedGetInquiries.cancel();
        };
    }, [debouncedGetInquiries]);


    useEffect(() => {
        if (list.resultObject) {
            setDocumentTemplate(JSON.parse(list.resultObject));
            let total = list.message.split(" ");
            setTotalData(Number(total[1]));
        } else {
            setDocumentTemplate([]);
            setTotalData(0);
        }
    }, [list]); // Dependency array includes `list` to trigger effect when it changes

    const documentPopupClose = () => setDocumentPopup(false);
    const editDocumentPopupClose = () => setEditDocumentPopup(false);
    const confirmDeletePopupClose = () => setConfirmDeletePopup(false);


    const handleAddDocument = async () => {
        setLoading(true);
        let formData = new FormData();
        Object.keys(addNewTemplateData).forEach(key => {
            formData.append(key, addNewTemplateData[key]);
        });
        if (addNewTemplateData.docType === 'Template') {
            formData.append("File", file);
        }

        const data = await InsertUpdateTemplateDocument(formData);
        if (data && data.status === 200) {
            dispatch(actions.templateDocumentForm.fetchTemplateDocumentList({
                pageIndex: pageIndex, pageSize: pageSize, status: status, searchText
            }));

            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "Template updated successfull", type: "success" }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            setAddNewTemplateData({ id: 0, documentName: '', existingLender: '', docuSign: false, allCases: false, chkBusiness: false, chkOwner: false, tempDocuFile: '', docType: '', BusinessType: '', ownerDocs: '', businessDocs: '', businessCatagory: '', postedFile: '' });
            documentPopupClose(true);
            setFile(null);
        } else {
            const errorMessage = "An error occurred while updating the template";
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: errorMessage, type: "error" }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        }
        setLoading(false);
    };


    const handleDeleteDocument = async () => {
        setLoading(true)
        const data = await DeleteTemplateDocument(selectedDocumentId);
        if (data && data.status === 200) {
            dispatch(actions.templateDocumentForm.fetchTemplateDocumentList({ pageIndex: pageIndex, pageSize: pageSize, status: '', searchText }));
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "Template removed successfull", type: "success" }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            setConfirmDeletePopup(false);
        } else {
            const errorMessage = "An error occurred while removing the template";
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: errorMessage, type: "error" }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        }
        setLoading(false);
    };


    const getEditDetails = async (data) => {
        setLoading(true);
        const response = await GetTemplateDocumentDetailsById(data.TD_Id);
        setEditTemplateData(response.resultObject);
        setLoading(false);
        setEditDocumentPopup(true)
    }


    const handleEditTemplateSave = async () => {
        setLoading(true);
        let formData = new FormData();
        Object.keys(editTemplateData).forEach(key => {
            formData.append(key, editTemplateData[key]);
        });
        if (editTemplateData.docType === 'Template') {
            formData.append("File", file);
        }
        const data = await InsertUpdateTemplateDocument(formData);
        if (data && data.status === 200) {
            dispatch(actions.templateDocumentForm.fetchTemplateDocumentList({
                pageIndex: pageIndex, pageSize: pageSize, status: status, searchText
            }));

            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: data.message, type: "success" }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            setAddNewTemplateData({ id: 0, documentName: '', existingLender: '', docuSign: false, allCases: false, chkBusiness: false, chkOwner: false, tempDocuFile: '', docType: '', BusinessType: '', ownerDocs: '', businessDocs: '', businessCatagory: '', postedFile: '' });
            editDocumentPopupClose(true);
            setFile(null);
        } else {
            const errorMessage = "An error occurred while updating the template";
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: errorMessage, type: "error" }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        }
        setLoading(false);
    }


    const templateDocuemntListTable = (data, index) => {
        return (
            <tr key={index}>
                <td><b>{data.RowNumber}</b></td>
                <td><b>{data.TD_Name}</b></td>
                <td>{data.TD_BusinessCatagory}</td>
                <td>{data.BusinessType}</td>
                <td>{lendersList.filter(lender => lender.value == data.TD_ExistingLender)[0]?.label}</td>
                <td>{data.TD_DocuSign === "True" ? "Yes" : "No"}</td>
                <td>{data.TD_AllCases === "True" ? "Yes" : "No"}</td>
                <td>
                    {
                        data.DocType === "UserUpload" ? (
                            <>
                                <a title="Delete"
                                    onClick={() => {
                                        setSelectedDocumentId(data.TD_Id)
                                        setConfirmDeletePopup(true)
                                    }}>
                                    <img className="lm-grid-edit-icon mr-2" src={trash_icon_grid} alt="" />
                                </a>
                                <a title="Edit"
                                    onClick={() => { getEditDetails(data) }}>
                                    <img className="lm-grid-edit-icon" src={edit_pen} alt="" />
                                </a>
                            </>
                        ) : ""
                    }
                </td>
            </tr>
        )
    }

    return (
        <>
            {/* SidePanel */}
            <SidePanel />

            {/* ProfileHeader */}
            <ProfileHeader />

            {loading ? (<Preloader />) : (<div>

                <div className="dashboard-wrap">
                    <div className="templates-wrap">
                        {/* <!-- Add document button --> */}
                        <div className="pb-4 d-flex flex-end">
                            <a onClick={() => setDocumentPopup(true)} className="lm-button3 ml-auto"><span>+</span> Add
                                document</a>
                        </div>
                        {/* <!-- Add document button --> */}
                        <div className="d-flex justify-content-between filter-search-group">
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                options={statusOptionsList}
                                defaultValue={statusOptionsList.find(option => option.value === status)}
                                onChange={(selectedOption) => {
                                    setPageIndex(1);
                                    setStatus(selectedOption.value)
                                }}
                            />
                            <div className="lm-search-bar">
                                <input type="text" id="searchInput" placeholder="Search by number, name, or other"
                                    value={searchText}
                                    onChange={(e) => {
                                        setPageIndex(1);
                                        setSearchText(e.target.value)
                                    }}
                                />
                            </div>
                        </div>

                        <DynamicTable
                            data={documentTemplate}
                            dynamicTableDisplay={templateDocuemntListTable}
                            columns={templateDocumentColumns}
                            changePageSize={setPageSize}
                            pageSize={pageSize}
                            total={totalData}
                            setList={setInquiries}
                            setPageIndex={setPageIndex}
                            pageIndex={pageIndex}
                        />


                        {/* <!-- templates grid/table --> */}

                        {/* <!--Add Document Popup / Modal --> */}
                        <Modal
                            size="lg"
                            show={documentPopupShow}
                            onHide={() => setDocumentPopup(false)}
                            aria-labelledby="documentPopupLabel"
                            backdrop="static"
                            keyboard={false}
                            centered
                        >
                            <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="documentPopupLabel">Add document</h5>
                                        <button type="button" className="close" onClick={documentPopupClose}>
                                            <span aria-hidden="true"><img src={close_popup} alt="" /></span>
                                        </button>
                                    </div>
                                    <form>
                                        <Modal.Body>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="documentName">Template Document Name</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="documentName"
                                                            placeholder="Enter Template Document Name"
                                                            value={addNewTemplateData.documentName}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="businessCatagory">Business Category</label>
                                                        <Select
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            menuPortalTarget={document.body}
                                                            styles={{
                                                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                            }}
                                                            options={businessCategoriesOptions}
                                                            name="businessCatagory"
                                                            onChange={handleSelectChange}
                                                        />
                                                    </div>
                                                    <div className="form-group form-check">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="chkBusiness"
                                                            checked={addNewTemplateData.chkBusiness}
                                                            onChange={handleInputChange}
                                                        />
                                                        <label className="form-check-label" htmlFor="chkBusiness">Required for business(s)</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="BusinessType">Business Type</label>
                                                        <Select
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            menuPortalTarget={document.body}
                                                            styles={{
                                                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                            }}
                                                            options={typeOfBusnessOptions}
                                                            name="BusinessType"
                                                            onChange={handleSelectChange}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="existingLender">Existing Lender</label>
                                                        <Select
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            menuPortalTarget={document.body}
                                                            styles={{
                                                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                            }}
                                                            options={lendersList}
                                                            name="existingLender"
                                                            onChange={handleSelectChange}
                                                        />
                                                    </div>
                                                    <div className="form-group form-check">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="chkOwner"
                                                            checked={addNewTemplateData.chkOwner}
                                                            onChange={handleInputChange}
                                                        />
                                                        <label className="form-check-label" htmlFor="chkOwner">Required for Owner(s)</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <div className="label pb-1">User Upload</div>
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="docType"
                                                                id="User Upload"
                                                                value="UserUpload"
                                                                checked={addNewTemplateData.docType === 'UserUpload'}
                                                                onChange={(e) => setAddNewTemplateData((prevState) => ({ ...prevState, docType: e.target.value }))}
                                                            />
                                                            <label className="form-check-label" htmlFor="User Upload">User Upload</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="docType"
                                                                id="Template"
                                                                value="Template"
                                                                checked={addNewTemplateData.docType === 'Template'}
                                                                onChange={(e) => setAddNewTemplateData((prevState) => ({ ...prevState, docType: e.target.value }))}
                                                            />
                                                            <label className="form-check-label" htmlFor="Template">Template</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    addNewTemplateData.docType === 'Template' ? (
                                                        <div className="col-md-6">
                                                            <div className="mb-2">
                                                                <span className="label mb-1">Upload template file</span>
                                                                {file ? (
                                                                    <div className="uploaded-file-box d-flex">
                                                                        <img src={pdf_file_icon} alt="" />
                                                                        <div className="ml-2">
                                                                            <div className="file-name">{file?.name}</div>
                                                                            <span className="text-light file-size">
                                                                                {(file?.size / (1024 * 1024)).toFixed(2)} MB
                                                                            </span>
                                                                        </div>
                                                                        <button type="button" className="close" onClick={() => setFile(null)}>
                                                                            <span><img src={close_popup} alt="" /></span>
                                                                        </button>
                                                                    </div>
                                                                ) : ""}
                                                                <div className="upload-box">
                                                                    <input type="file" id="upload-file" accept=".cshtml" onChange={handleFileChange} />
                                                                    <div className="upload-text">
                                                                        Drop CSHTML file(s) here or <button type="button" id="upload-button">Choose Here</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : ""
                                                }

                                            </div>
                                        </Modal.Body>
                                        <div className="modal-footer la-app-modal-footer">
                                            <button type="button" className="lm-button2" onClick={documentPopupClose}>Cancel</button>
                                            <button type="button" className="lm-button1" onClick={handleAddDocument}>Save</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Modal>
                        {/* <!--Add Document Popup / Modal --> */}

                        {/* <!--edit Document drawer--> */}
                        <Modal
                            className="modal fade fade-right drawer-modal"
                            show={editDocumentPopupShow}
                            onHide={() => setEditDocumentPopup(false)}
                            aria-labelledby="editDocumentPopupLabel"
                        >
                            <div className="modal-dialog">
                                <form action="">
                                    <div className="modal-content">
                                        <div className="drawer-head d-flex justify-content-between">
                                            <h3>Edit document</h3>
                                            <button type="button" className="close" onClick={editDocumentPopupClose}>
                                                <span aria-hidden="true"><img src={close_popup} alt="" /></span>
                                            </button>
                                        </div>
                                        <div className="drawer-body">
                                            <div className="form-group">
                                                <label htmlFor="document_name">Template Document Name</label>
                                                <input
                                                    type="text"
                                                    id="documentName"
                                                    className="form-control"
                                                    placeholder="Enter Template Document Name"
                                                    value={editTemplateData.documentName}
                                                    onChange={handleEditInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="category">Business Category</label>
                                                <Select
                                                    id="businessCatagory"
                                                    name="businessCatagory"
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    menuPortalTarget={document.body}
                                                    styles={{
                                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                    }}
                                                    options={businessCategoriesOptions}
                                                    defaultValue={businessCategoriesOptions.find(option => option.value === editTemplateData.businessCatagory)}
                                                    onChange={handleEditSelectChange}
                                                />
                                            </div>
                                            <div className="form-group form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="chkBusiness"
                                                    checked={editTemplateData.chkBusiness}
                                                    onChange={handleEditInputChange}
                                                />
                                                <label className="form-check-label" htmlFor="required_for_business">Required for
                                                    business(s)</label>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="type">Business Type</label>
                                                <Select
                                                    name="businessType"
                                                    id="businessType"
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    options={typeOfBusnessOptions}
                                                    defaultValue={typeOfBusnessOptions.find(option => option.value === editTemplateData?.businessType)}
                                                    onChange={handleEditSelectChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="existing_lender">Existing Lender</label>
                                                <Select
                                                    id="existingLender"
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    menuPortalTarget={document.body}
                                                    styles={{
                                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                    }}
                                                    options={lendersList}
                                                    defaultValue={lendersList.find(option => option.value == editTemplateData?.existingLender)}
                                                    name="existingLender"
                                                    onChange={handleEditSelectChange}
                                                />
                                            </div>
                                            <div className="form-group form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="chkOwner"
                                                    checked={editTemplateData.chkOwner}
                                                    onChange={handleEditInputChange}
                                                />
                                                <label className="form-check-label" htmlFor="required_for_owners" >Required for  Owners(s)</label>
                                            </div>
                                            <div className="form-group">
                                                <div className="label pb-1">User Upload</div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="docType"
                                                        id="User Upload"
                                                        value="UserUpload"
                                                        checked={editTemplateData.docType === 'UserUpload'}
                                                        onChange={(e) => setEditTemplateData((prevState) => ({ ...prevState, docType: e.target.value }))}
                                                    />
                                                    <label className="form-check-label" htmlFor="User Upload">User Upload</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="docType"
                                                        id="Template"
                                                        value="Template"
                                                        checked={editTemplateData.docType === 'Template'}
                                                        onChange={(e) => setEditTemplateData((prevState) => ({ ...prevState, docType: e.target.value }))}
                                                    />
                                                    <label className="form-check-label" htmlFor="Template">Template</label>
                                                </div>
                                            </div>
                                            {
                                                editTemplateData.docType === 'Template' ? (
                                                    <div className="col-md-12">
                                                        <div className="mb-2">
                                                            <span className="label mb-1">Upload template file</span>
                                                            {file ? (
                                                                <div className="uploaded-file-box d-flex">
                                                                    <img src={pdf_file_icon} alt="" />
                                                                    <div className="ml-2">
                                                                        <div className="file-name">{file?.name}</div>
                                                                        <span className="text-light file-size">
                                                                            {(file?.size / (1024 * 1024)).toFixed(2)} MB
                                                                        </span>
                                                                    </div>
                                                                    <button type="button" className="close" onClick={() => setFile(null)}>
                                                                        <span><img src={close_popup} alt="" /></span>
                                                                    </button>
                                                                </div>
                                                            ) : ""}
                                                            <div className="upload-box">
                                                                <input type="file" id="upload-file" accept=".cshtml" onChange={handleFileChange} />
                                                                <div className="upload-text">
                                                                    Drop CSHTML file(s) here or <button type="button" id="upload-button">Choose Here</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : ""
                                            }
                                        </div>
                                        <div className="drawer-footer d-flex">
                                            <button type="button" className="lm-button1 ml-auto" onClick={handleEditTemplateSave}>Save</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </Modal>
                        {/* <!--edit Document drawer--> */}


                        {/* <!--Confirm Delete Popup / Modal --> */}
                        <Modal
                            className="mark-comp-popup-cust-size"
                            show={confirmDeletePopupShow}
                            onHide={() => setConfirmDeletePopup(false)}
                            aria-labelledby="confirmDeletePopupLabel"
                            backdrop="static"
                            keyboard={false}
                            centered
                        >
                            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                <div className="modal-content">
                                    <div className="modal-body signout-modal">
                                        <h3 className="pt-4">
                                            Are you sure you want to delete?
                                        </h3>
                                    </div>
                                    <div className="modal-footer la-app-modal-footer">
                                        <button type="button" className="lm-button2" onClick={confirmDeletePopupClose}>Cancel</button>
                                        <button type="button" className="lm-button1" onClick={handleDeleteDocument}>Confirm</button>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                        {/* <!--Confirm Delete Popup / Modal --> */}

                    </div>
                </div>

            </div>)}


        </>
    );
};

export default TemplateDocumentComponent;
