import { useEffect } from "react";
import { DownloadZipFileWithURL } from "../services/documentsServices";

function DownloadZipFile() {
    useEffect(() => {
        getZipFileFromURL();
    }, []);

    function getCurrentUrlParams() {
        const params = {};
        const parsedUrl = new URL(window.location.href);
    
        // Iterate through all search parameters
        for (const [key, value] of parsedUrl.searchParams.entries()) {
            params[key] = value;
        }
    
        return params;
    }
    
    const getZipFileFromURL = async () => {
        const currentUrlParams = getCurrentUrlParams()

        await DownloadZipFileWithURL(currentUrlParams.fileName);
        window.close();
    }

    return null; // Since this component doesn't render any UI, we return null
}

export default DownloadZipFile;
